import React, { useEffect, useState } from 'react';
import { 
  Container,
  RecommendContainer,
  RecommendText,
  SearchButton,
  SearchButtonContainer,
  SearchIcon,
  Wrapper,
} from './styles';
import { 
	collection, 
	getDocs, 
} from 'firebase/firestore';
import { db } from '../../firebase';
import SearchIconImg from '../../utils/imgs/search_button_icon.svg';
import { useNavigate } from 'react-router-dom';
interface RecommendProps {
  title: string;
  id: string
}
const RecommendationBar = ({
  scrolled
}: {
  scrolled: boolean;
}) => {
  const [randomRecommendIndx, setRandomRecommendIndx] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const [recommend, setRecommend] = useState<any[]>([]);
  const setRandomRecommend = () => {
    const randomIndex = Math.floor(Math.random() * recommend.length);
    setRandomRecommendIndx(randomIndex);
    
  }
  const navigation = useNavigate();

  const handleClickIcon = () => {
    navigation(`search?question=${recommend[randomRecommendIndx].id}`);
  };
  const handleClickIconAtScrolled = () => {
    navigation('/search');
  }
  const getRecommends = async () => {
    const querySnapshot = await getDocs(collection(db, "bochung_recommends"));
    const docsdata = querySnapshot.docs.map((doc) => doc.data());
    setRecommend(docsdata);
    setLoading(true);
  }
  useEffect(() => {
    if(!loading) getRecommends();
    if(loading) setRandomRecommend();
  }, [loading]);
  
  return (
    <Container>
      {
        !scrolled ?
        <Wrapper>
          <RecommendContainer onClick={() => navigation('/search')}>
              <RecommendText>
                {loading && recommend[randomRecommendIndx].title}
              </RecommendText>
          </RecommendContainer>
          <SearchButtonContainer>
            <SearchButton onClick={handleClickIcon}>
              <SearchIcon src={SearchIconImg} alt="search_ico"/>
            </SearchButton>
          </SearchButtonContainer>
        </Wrapper>
        :
        <SearchButtonContainer>
          <SearchButton onClick={handleClickIconAtScrolled}>
            <SearchIcon src={SearchIconImg} alt="search_ico"/>
          </SearchButton>
        </SearchButtonContainer>
      } 
    </Container>
  );
};

export default RecommendationBar;