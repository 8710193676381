import styled from '@emotion/styled';

export const SigninPageContainer = styled.div`
	width: 100vw;
	margin-bottom: 47px;
`;
export const SigninPageWrapper = styled.div`	
	margin-top: 100px;
`;
export const Header = styled.div`
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	background-color: #181A20;
`;
export const Wrapper = styled.div`
	padding: 18px 16px;
	display: flex;
	flex-direction: row;
`;
export const BackButton = styled.button`
	width: 32px;
	height: 32px;
`;
export const BackButtonImg = styled.img``;
export const Title = styled.span`
	font-size: 18px;
	color: #ebebeb;
	margin: auto;
`;
export const Dummy = styled.div`
	width: 32px;
	height: 32px;
`;
export const SigninConatiner = styled.div`
`;
export const SigninTitleContainer = styled.div`
	margin-bottom: 10px; 
`;
export const SigninTitle = styled.span`
	font-size: 18px;
	font-weight: bold;
`;

export const SigninInputContainer = styled.div`
	width: 100%;
	margin-bottom: 20px;

`;
export const SigninInputWrapper = styled.div`
	width: 100%;
	display: flex;
`;
export const SigninInput = styled.input`
	flex-grow:1;
	height: 56px;
	margin-bottom: 10px;
	padding: 0 5px;
	font-size: 16px;
	border-bottom: 1px solid #ededed;
	color: #ededed;
`;

export const ButtonsContainer = styled.div``;
export const SignInButtonsContainer = styled.div`
	width: 100%;
`;
export const SigninButton = styled.button`
	width: 100%;
	height: 56px;
	border-radius: 8px;
	background-color: #F73C61;
	color: white;
	
	font-weight: bold;
	font-size: 16px;
	
	margin-bottom: 10px;
	
	margin-top: 24px;
`;
export const SignupButton = styled.button`
	height: 40px;
	border-radius: 10px;

`;

export const SigninInfoButtonContainer = styled.div`
	margin-top: 16px;
`;
export const SigninInfoButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	gap: 10px;
	
	color: #ededed;
`;

export const FindIdButton = styled.button``;
export const FindIdTitle = styled.span`
	font-size: 14px;
	color: #ededed;
`;
export const FindPasswordButton = styled.button``;
export const FindPasswordTitle = styled.span`
	font-size: 14px;
	color: #ededed;
`;
export const SignUpButton = styled.button``;
export const SignUpTitle = styled.span`
	font-size: 14px;
	color: #ededed;
`;
