import styled from '@emotion/styled';

export const SearchBarConatiner = styled.div`
	width: 100%;

`;
export const SearchBarWrapper = styled.div`
	border-radius: 8px;
	border: 1px solid ${props => props.theme.colors.searchBarBorder};
	padding: 10px 13px;
	background-color: ${props => props.theme.colors.searchBarBackground};
	display: flex;
	flex-direction: row;
`;
export const SearchBarInput = styled.input`
	width: 100%;
	color: #646464;
	font-size: 14px;
`;
export const SearchButton = styled.div``;
export const SearchButtonImg = styled.img``;