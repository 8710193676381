import React, { useEffect, useState, useMemo } from 'react';
import {
	Container,
	Wrapper,
	ListItemContainer,
	ListItemWrappper,
	ListItem,
	DescContainer,
	DescWrapper,
	DescInnerContainer,
	SortButtonContainer,
	SortButtonWrapper,
	SortButtonImgContainer,
	SortButtonImg,
	SortButtonTitle,
	SortButton,
	ListLoadingContainer,
	IntroduceNutrientContainer,
	IntroduceNutrientTitleContainer,
	IntroduceNutrientTitle,
	NutrientButtonConatainer,
	NutrientButton,
	NutrientButtonTitle,
	DescText,
	DescTitle
} from './styles';
import { useNavigate } from 'react-router-dom';
import { 
	collection, 
	getDocs, 
	query, 
	where, 
} from 'firebase/firestore';
import { db } from '../../firebase';
import { SupplementListItem } from '../../components/item';
import { Footer, Header } from '../../components';
import SortPopup from '../../components/modals/sortPopup';
import SortButtonImage from '../../utils/imgs/Vector 6.svg';
import Loading from '../../components/item/supplement/Loading';
import MetaTag from '../../components/meta';

interface NutrientProps {
	id: number;
	label: string;
	active: boolean;
	nutrientId: string;
	desc: string;
}
interface SupplementInfoProps{
	kcal: number;
	protein: number;
	fat: number;
	serving: number;
}
interface CompanyProps {
	id: string;
	name: string;
}
interface SupplementProps {
	id: string;
	company: CompanyProps;
	name: string;
	photo: {
		url: string;
	};
	nutrients: any[];
	tags: string[];
	infos: SupplementInfoProps;
	desc: string;
	price: number;
	weight: number;
	unit: string;
}
interface SortType {
	index: string;
	title: string;
}

const HomePage = () => {
	const [supplements, setSupplements] = useState<SupplementProps[]>([]);
	const [nutritientButtons, setNutritientButtons] = useState<NutrientProps[]>([
		{ 
			id: 1, 
			label: 'WPC', 
			active: true, 
			nutrientId: 'WPC', 
			desc: 'WPC는 농축유청단백질로, WP를 농축해서 만든 성분이에요. 평균 단백 순도가 80%정도이고 처리 과정이 상대적으로 짧아 가격이 저렴해요.'
		},
		{ 
			id: 2, 
			label: 'WPI', 
			active: false, 
			nutrientId: 'WPI',
			desc: 'WPI는 분리유청단백질로, WP를 한번 더 거른 성분이에요. 유당불내증이나 좋은 품질의 단백질을 찾는 분에게 추천해요.'
		},
		{ 
			id: 3, 
			label: 'WPH', 
			active: false, 
			nutrientId: 'WPH', 
			desc: 'WPH는 가수분해유청단백질로, WPC를 원료로 소화요소를 추가해 한 번 더 가수분해한 성분이에요. 단백질 순도가 96%이상이고 체내흡수 속도가 가장 빠른 편이에요.'
		},
		{ 
			id: 4, 
			label: 'WPIH', 
			active: false, 
			nutrientId: 'WPIH', 
			desc: 'WPIH는 가수분해분리유청단백질로, 인체의 단백질 흡수에 필수적인 과정인 부분 가수 분해를 거쳐 잘 소화되는 유청단백질 형태의 성분이에요. 의료용 단백질 보충제와 분유로 흔히 사용되고 있어요.' 
		},
	]);
	const sortList: SortType[] = [
		{
			title: '낮은 가격 순',
			index: 'desc'
		},
		{
			title: '높은 가격 순',
			index: 'incr'
		}
	]
	const [currentNutrient, setCurrentNutrient] = useState<string>('WPC');
	const [loading, setLoading] = useState<boolean>(false);
	const [sortType, setSortType] = useState<SortType>(sortList[0]);
	const [popupState, setPopupState] = useState<boolean>(false);
	const [scrolled, setScrolled] = useState<boolean>(false);
 	
	const navigate = useNavigate();

	const handleOnClickNutrientButton = (buttonId: number) => {
		setLoading(false);
		const updateButtons = nutritientButtons.map((nutrient) => {
			if (nutrient.id === buttonId) {
				setCurrentNutrient(nutrient.label);
				
				return {
					...nutrient,
					active: true,
				};
			} else {
				return {
					...nutrient,
					active: false,
				}
			}
		});

		setNutritientButtons(updateButtons);
	};
	const homeDesc = `보충제 뭘 먹어야 할지 모르겠다고? 보충에서 찾아봐!`;

	const QueryForFirebaseWithNutrientId = async () => {

		const q = query(
			collection(db, 'bochung_supplements'),
			where("nutrients", 'array-contains', currentNutrient ?? ''),
		);

		const querySnapshot = await getDocs(q);
		const docsData = querySnapshot.docs.map((doc) => {return {data: doc.data(), id: doc.id}});

		const data: SupplementProps[] = docsData.map((d) => {
			return {
				id: d.id,
				company: d.data.company,
				name: d.data.name,
				photo: d.data.photo,
				nutrients: d.data.nutrients ?? [],
				tags: d.data.tags ?? [],
				infos: d.data.infos,
				desc: d.data.desc,
				price: d.data.price,
				weight: d.data.weight,
				unit: d.data.unit,
			};
		});
		
		setSupplements(data);
		setLoading(true);
	};

	const getSortedList = (sortType: SortType) => {
		const list  = supplements;
		if(sortType.index === 'desc') {
			list.sort((a: SupplementProps, b: SupplementProps): number => {
				return a.price - b.price;
			}); 
		} else if(sortType.index === 'incr') {
			list.sort((a: SupplementProps, b: SupplementProps): number => {
				return b.price - a.price;
			});
		} else if(sortType.index === 'recommend') {
			list.sort((a: SupplementProps, b: SupplementProps): number => {
				return b.price - a.price;
			});
		}
		return list;
	}

	const handleSortType = (sortType: SortType) => {
		setSortType(sortType);
		setPopupState(false);
	}
	const handleScroll = () => {
		const isScrolled = window.scrollY > 48;
		setScrolled(isScrolled);
	};
	const momorizeList = useMemo(
		() => getSortedList(sortType),
		[sortType, supplements]
	);

	useEffect(() => {
		if(!loading) QueryForFirebaseWithNutrientId();
 
		if(popupState) document.body.style.overflow = 'hidden';
		else document.body.style.overflow = 'unset';

		handleScroll();

		window.addEventListener('scroll', handleScroll);
		
		return () => {
			window.removeEventListener('scroll', handleScroll);
		}

	}, [
		nutritientButtons,
		currentNutrient, 
		sortType, 
		loading, 
		popupState
	]);

	return (
		<>
		<MetaTag
				title='보충 - 단백질 보충제 뭘 골라서 먹어야 할지 모르곘다면?'
				url='https://bo-chung.com'
				description={homeDesc}
			/>
		<Header
			scrolled={scrolled}
		/>

		<SortPopup 
			sortList={sortList} 
			handleSort={handleSortType} 
			onCloseButton={setPopupState}
			state={popupState}
			currentSortType={sortType}
		/>

		<Container isActive={popupState} scrolled={scrolled}>
			<Wrapper>

				<IntroduceNutrientContainer>
					<IntroduceNutrientTitleContainer>
						<IntroduceNutrientTitle>대표 성분 4가지를 소개합니다</IntroduceNutrientTitle>
					</IntroduceNutrientTitleContainer>

					<NutrientButtonConatainer>
						{
							nutritientButtons.map((nutrient) => (
								<NutrientButton 
									key={nutrient.id} 
									onClick={() => handleOnClickNutrientButton(nutrient.id)} 
									clicked={nutrient.active}
								>
									<NutrientButtonTitle>{nutrient.label}</NutrientButtonTitle>
								</NutrientButton>
							))
						}
					</NutrientButtonConatainer>

					<DescContainer>
						<DescWrapper>
							{
								nutritientButtons.map((n) => {
									if(n.active) {
										return (
											<div key={n.id}>
												<DescInnerContainer key={n.id}>
													<DescText>{n.desc}</DescText>
												</DescInnerContainer>
												<DescTitle>{n.label}</DescTitle>
											</div>
										)
									}
								})
							}
						</DescWrapper>
					</DescContainer>
				</IntroduceNutrientContainer>

				<ListItemContainer>
					<ListItemWrappper>

						<SortButtonContainer>
							<SortButtonWrapper>
								<SortButton onClick={() => setPopupState(true)}>
										<SortButtonTitle>{sortType.title}</SortButtonTitle>
										<SortButtonImgContainer>
											<SortButtonImg src={SortButtonImage} alt='img'/>
										</SortButtonImgContainer>
								</SortButton>
							</SortButtonWrapper>
						</SortButtonContainer>

						{
							loading ?
								momorizeList.map((s: SupplementProps) => (
									<ListItem 
										key={s.id} 
										onClick={() => navigate(`detail?id=${s.id}`)}
									>
										<SupplementListItem supplement={s}/>
									</ListItem>
								))
							:
							<ListLoadingContainer>
								{/* <Loading/>
								<Loading/>
								<Loading/>
								<Loading/>
								<Loading/> */}
							</ListLoadingContainer>
						}

					</ListItemWrappper>
				</ListItemContainer>

			</Wrapper>
			
		</Container>
		<Footer/>
		</>
	);
};

export default HomePage;


