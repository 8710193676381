import styled from '@emotion/styled';

export const NotFoundContainer = styled.div`
  flex:1;
  witdh: 100vw;
`;
export const NotFoundWrapper = styled.div`
  padding: 0 16px;
`;
export const NotFoundContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: 20px;
`;
export const NotFoundImg = styled.img`
  width: 150px;
  height: 150px;
`;
export const NotFoundTitle = styled.span`
  color: #ebebeb;
  font-size: 18px;
`;