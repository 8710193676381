import axios, { HttpStatusCode } from 'axios';
import isEqual from 'lodash/isEqual';
import { db } from '../firebase';
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';

export const isObjInArray = <T, >(arr: T[], obj: T): boolean => {
    return arr.some((item) => JSON.stringify(item) === JSON.stringify(obj));
}
  
export const addValueToArray = <T, >(arr: T[],  v: T) : T[] => {
    if (isObjInArray(arr, v)) {
      return arr.filter((item) => !isEqual(item, v));
    }
    return [...arr, v];
}
export const getAccessToken = async (code: string) => {
  const response = await axios.post(
    `https://kauth.kakao.com/oauth/token`, {
      grant_type: 'authorization_code',
      client_id: process.env.REACT_APP_REST_API_KEY,
      client_secret: process.env.REACT_APP_REST_API_SECRET,
      code: code,
      redirect_uri: process.env.REACT_APP_REDIRECT_URL,
    },{
    headers: {
      'Content-type': 'application/x-www-form-urlencoded',
    }
  });

  if(response.status === HttpStatusCode.Ok) {
    return response.data;
  } else return response.status;
}
export const checkAccessToken = async (accessToken: string) => {
  const response = await axios.get(
    'https://kapi.kakao.com/v1/user/access_token_info', {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
  });

  if(response.status === HttpStatusCode.Ok) {
    return response.data;
  } else return response.status;
}
export const checkRefreshToken  = () => {

}
export const getNewAccessTokenWithRefreshToken = async ( refreshToken: string ) => {
  await axios.post(
    'https://kauth.kakao.com/oauth/token', {
      grant_type: 'refresh_token',
      client_id: process.env.REACT_APP_REST_API_KEY,
      refreshToken: refreshToken,
      client_secret: process.env.REACT_APP_REST_API_SECRET,
    }, {
      headers: {
        'Content-type': 'application/x-www-form-urlencoded',
      }
    }
  ).then((res) => {
  }).catch((err) => {

  })
}
export const saveAccessToken = () => {

}
export const getAccessTokenFromLocal = () => {

}
export const IsUserExistWithKakaoUserId = async (kakaoUserId: number) => {
  console.log(kakaoUserId);
  const docRef = query(
    collection(db, 'bochung_users'),
    where('kakaoId', '==', kakaoUserId)
  );
  const docSnap = await getDocs(docRef); 
 
  if(docSnap.docs.length === 0) return false;
  else return docSnap.docs[0].id;
}

export const getKakaInfoWithAccessToken = async (accessToken: string) => {
  const response = await axios.post(
    'https://kapi.kakao.com/v2/user/me',
    {
      property_keys: ["kakao_account.email"]
    },
    {
      headers: {
        'Authorization' : `Bearer ${accessToken}`,
        'Content-type': 'application/x-www-form-urlencoded',
      }
    }
  )

  if(response.status === HttpStatusCode.Ok) {
    return response.data;
  } else return response.status;

}

export const logoutWithAccessToken = async (accessToken: string, id: number) => {
  return await axios.post(
    'https://kapi.kakao.com/v1/user/logout',{
      target_id_type: 'user_id',
      target_id: id
    },
    {
      headers: {
        'Authorization' : `Bearer ${accessToken}`,
      }
    }
  )
}
export const checkAuthorization = async (userNumber: number, userId: string) => {
  const response = await IsUserExistWithKakaoUserId(userNumber);
  console.log(response, userId);
  if(response === userId) return true;
  else return false;
}
