import styled from '@emotion/styled';

export const SupplementName = styled.span`
	font-size: ${props => props.theme.fontSizes.medium};
	font-weight: medium;
	line-height: 140%;
	margin-bottom: 4px;
	color: ${props => props.theme.colors.cardText};
`;
export const ListItemInfoArea = styled.div`
	width: 100%;
`;
export const SummeryContainer = styled.div`
	display: flex;
	flex-direction: row;
	background-color: ${props => props.theme.colors.cardBackground};
	padding: 12px 16px;
	border-radius: 4px;

`;

export const SummeryNutrientFacts = styled.div`
	display: flex;
	flex-direction: column;
	font-size: ${props => props.theme.fontSizes.small};
	line-height: 140%;
	color: ${props => props.theme.colors.cardText};
`;

export const SupplementPrice = styled.span`
	font-size: 16px;
	font-weight: medium;
	color: #F73C61;
`;

export const InfoText = styled.span`
	font-size: 12px;
`;

export const SummeryInner = styled.div`
	display: flex;
	flex-direction: column;
	color: white;
`;
