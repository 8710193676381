import React from 'react';
import {
	DetailContainer,
	DetailWrapper,
	InfoContainer,
	InfoItem,
	SupplementNameConatiner,
	NutrientsContainer,
	SmallItemContainer,
	SupplementName,
	Infos,
	NutrientsWrapper,
	NutrientListInner,
	CompanyContainer,
	CompanyWrapper,
	CompanyTitle,
	CompanyHompageLink,
	PriceContainer,
	PriceWrapper,
	PhotoConatiner,
	Photo,
	InfoTitle,
	InfoContent,
	Price,
	Weight,
	SubPrice,
	NutrientName,
} from './styles';

interface SupplementInfoProps{
	kcal: number;
	protein: number;
	fat: number;
	serving: number;
	carbohydrate: number;
	sugars: number;
}
interface CompanyProps {
	name: string;
	url: string;
}
interface SupplementProps {
	id: string;
	company: string;
	name: string;
	photo: {
		url: string;
	};
	nutrients: string[];
	tags: string[];
	infos: SupplementInfoProps;
	desc: string;
	price: number;
	weight: number;
	link: string;
  unit: string;
}

const DetailView = ({
  supplement,
  company,
}: {
  supplement: SupplementProps,
  company: CompanyProps,
}) => (
  <DetailContainer>
    <DetailWrapper>
      <InfoContainer>
        <PhotoConatiner>
          <Photo src={supplement?.photo.url} alt='img'/>
        </PhotoConatiner>
        
        <Infos>
          <InfoItem>
            <InfoTitle>1회 제공량</InfoTitle>
            <InfoContent>{supplement?.infos.serving}g</InfoContent>
          </InfoItem>
          <InfoItem>
            <InfoTitle>열량</InfoTitle>
            <InfoContent>{supplement?.infos.kcal}kcal</InfoContent>
          </InfoItem>
          <InfoItem>
            <InfoTitle>지방</InfoTitle>
            <InfoContent>{supplement?.infos.fat}g</InfoContent>
          </InfoItem>
          <InfoItem>
            <InfoTitle>단백질</InfoTitle>
            <InfoContent>{supplement?.infos.protein}g</InfoContent>
          </InfoItem>
          <InfoItem>
            <InfoTitle>탄수화물</InfoTitle>
            <InfoContent>{supplement?.infos.carbohydrate}g</InfoContent>
          </InfoItem>
          <InfoItem>
            <InfoTitle>당류</InfoTitle>
            <InfoContent>{supplement?.infos.sugars}g</InfoContent>
          </InfoItem>

        </Infos>
      </InfoContainer>
      
      <NutrientsContainer>
        <NutrientsWrapper>
          <NutrientListInner>
            {
              supplement?.nutrients.map((nutrient: string) => (
                <SmallItemContainer 
                  type='button' 
                  key={nutrient}
                >
                  <NutrientName>{nutrient}</NutrientName>
                </SmallItemContainer>
              ))
            }
          </NutrientListInner>
        </NutrientsWrapper>
      </NutrientsContainer>

      <CompanyContainer>
        <CompanyWrapper>
          <CompanyTitle >
              <CompanyHompageLink>
                <a 
                  href={company.url} 
                  target='_blank' 
                  rel='noreferrer'
                >
                  <span>{company.name}</span>
                </a>
              </CompanyHompageLink>
          </CompanyTitle>
        </CompanyWrapper>
      </CompanyContainer>
      <SupplementNameConatiner>
        <SupplementName>{supplement?.name}</SupplementName>
      </SupplementNameConatiner>
    
      {
        supplement?.weight !== undefined ?
        <PriceContainer>
          <PriceWrapper>
            <Weight>용량 : {supplement?.weight.toLocaleString('ko-KR')}{supplement?.unit || 'kg'}</Weight>
          </PriceWrapper>
        </PriceContainer>
        : <></>
      }
      {
        supplement?.price !== undefined ?
        <PriceContainer>
          <PriceWrapper>
            <Price>{supplement?.price.toLocaleString('ko-KR')}원</Price>
            <SubPrice>(10g 당 {(supplement?.price / (supplement?.weight * 1000)).toFixed(2)}원)</SubPrice>
          </PriceWrapper>
        </PriceContainer>
        : <></>
      }
    </DetailWrapper>
  </DetailContainer>
);

export default DetailView;