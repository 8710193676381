import React, { useEffect, useState } from 'react';
import { 
  addDoc,
  collection,
  doc,
  getDocs,
  setDoc,
} from 'firebase/firestore';
import { db } from '../../../firebase';
import { Tag } from '../../../interfaces';

const TagView = () => {
  const [ name, setName ] = useState<string>('');
  const [ list, setList ] = useState<Tag[]>([]);

  const collectionName: string = 'bochung_tags';

  const getTagList = async () => {
    const docRef = await getDocs(collection(db, collectionName));
    const updatedArr: Tag[] = docRef.docs.map((doc) => {return { name: doc.data().name, id: doc.id }});
    setList(updatedArr);
  }
  const fetchTag = async() => {
    const docRef =  await setDoc(doc(db, collectionName, name), { name: name });
  }
   const resetInput = () => {
	  setName('');
  }
  const onClick = () => {
	  fetchTag();
	  resetInput();
  }

  useEffect(() => {
    getTagList();
  }, []);

  return(
    <div>
      <div>
        <div>
          <span>등록된 태그들</span>
        </div>
        <div>
          {
            list.map((item) => (
              <div>{item.name}</div>
            ))
          }
        </div>
      </div>
      <div>
        <div>이름</div>
        <div>
          <input placeholder="태그 이름" onChange={(e) => setName(e.target.value)} value={name}/>
        </div>
        <div>
          <button onClick={onClick}>
            <span>태그 등록하기</span>
          </button>
        </div>
      </div>
    </div>
  )
};

export default TagView;