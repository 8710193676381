import React from 'react';
import {
	FooterContainer,
	FooterInner,
	Contact,
	ContactInfo,
	ContactButton,
} from './styles';

const Footer = () => {
	return (
		<FooterContainer>
			<FooterInner>
				<Contact>
					<ContactButton>
						<div>보충에 대해서 궁금하신가요?</div>
					</ContactButton>
					<ContactInfo>
						<div>chosh04089@gmail.com</div>
						<div>2023 보충. All rights reserved.</div>
					</ContactInfo>
				</Contact>
			</FooterInner>
		</FooterContainer>
	);
};



export default Footer;