import styled from '@emotion/styled';

export const ReviewsContainer = styled.div`
  margin-top: 40px;
`;
export const ReviewsWrapper = styled.div`

`;

export const ReviewTitleContainer = styled.div`
  margin-bottom: 8px;
`;
export const ReviewTitle = styled.span`
  font-size: 16px;
  font-weight: bold;
`;
export const ReviewCount = styled.span`
  color: #F73C61;
  margin-left: 4px;
`;

export const ReviewScoreContainer = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  
`;
export const ReviewScoreWrapper = styled.div`
  padding: 4px 8px;
`;
export const ScoreListContainer = styled.ul`
  width: 100%;  
  display: flex;  
  flex-direction: row;
  gap: 8px;
  justify-content: center;
`;
export const ScoreListItem = styled.li`
  list-style-type: none;
`;
export const ScoreProgressBar = styled.progress``;
export const ScoreItemTitle = styled.span``;
export const AvgScore = styled.span`
  font-size: 28px;
`;
export const ScoreDetail = styled.div`
`;
export const ScoreContainer = styled.div`
 display: flex;
`;
export const ScoreName = styled.div``;
export const Score = styled.progress``;

export const ReviewListConatiner = styled.div`
  margin-top: 40px;
`;

export const NoReviewContainer = styled.div`
  height: 100px;
  margin-top: 40px;
  display: flex;
  justify-content: center;
`;
export const NoReviewTitle = styled.span``;

export const WriteReviewContainer = styled.div``;
export const WriteReviewButton = styled.button`
  background-color: #F73C61;
  border-radius: 4px;
  padding: 4px 12px;
  height: 40px;
  width: 100%;
`;
export const WriteReviewButtonTitle = styled.span`
  color: white;
  font-weigth: bold;
  font-size: 18px;
`;

export const SortBarContainer = styled.div``;
export const SortBarWrapper = styled.div``;
export const SortListContainer = styled.div``;
export const SortListItem = styled.button``;
export const SortItemListTitle = styled.span``;