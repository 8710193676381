import React from 'react';
import { 
  NotFoundContainer, 
  NotFoundContent, 
  NotFoundImg, 
  NotFoundTitle, 
  NotFoundWrapper 
} from './styles';
import Img from '../../../utils/imgs/favicon.png';
import { Footer, Header } from '../../../components';
import MetaTag from '../../../components/meta';

const NotFoundPage = () => (
  <>
    <MetaTag
      title='404 Not Found'
      description='페이지를 검색할 수 없습니다.'
      url=""
    />
    <Header scrolled={false}/>
    <NotFoundContainer>
      <NotFoundWrapper>
        <NotFoundContent>
          <NotFoundImg src={Img} alt='logo_img'/>
          <NotFoundTitle>페이지를 찾을 수 없어요</NotFoundTitle>
        </NotFoundContent>
      </NotFoundWrapper>
    </NotFoundContainer>
    <Footer/>
  </>
);

export default NotFoundPage;