import React from 'react';
import { Helmet } from 'react-helmet-async';

const MetaTag = ({
  title,
  description,
  url
} : {
  title: string | undefined,
  description: string | undefined,
  url: string,
}) => (
  <Helmet>
    <meta charSet='utf-8'/>
    <meta property="og:url" content={url}/>
    <meta property="og:type" content="website" />
    <meta name="description" lang='kr' content={description}/>
    <title lang='kr'>{title}</title>
  </Helmet>
);

export default MetaTag;