import styled from '@emotion/styled';

export const Container = styled.div`
  box-sizing: border-box;
`;
export const Wrapper = styled.div`
  
  border: 1px solid ${props => props.theme.colors.searchBarBorder};
  border-radius: 12px;

  display: flex;
  flex-direction: row;
  background-color: ${props => props.theme.colors.searchBarBackground};

  padding: 8px 14px;
`;

export const RecommendContainer = styled.div`
  color: #646464;
  margin: auto;
  width: 100%;
`;
export const RecommendWrapper = styled.div`
 
 
`;
export const RecommendText = styled.span`
  font-size: 14px;
`;

export const SearchButtonContainer = styled.div`
  width: 24px;
  height: 24px;  
`;
export const SearchButton = styled.button`
`;
export const SearchIcon = styled.img`
  
`;