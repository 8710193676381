import React from 'react';
import { Route, Routes } from 'react-router-dom';
import {
	HomePage,
	DetailPage,
	SearchPage,
	AdminPage,
	NotFoundPage
} from '../pages';

const AppRouter = () => {
	return (
		<Routes>
			<Route path="/" element={<HomePage/>} />
			<Route 
				path="/detail/*" 
				element={<DetailPage/>} 
			/>
			<Route
				path="/search/*"
				element={<SearchPage/>}
			/>
			<Route
				path='/admin/*'
				element={<AdminPage/>}
			/>
			<Route
				path='*'
				element={<NotFoundPage/>}
			/>
		</Routes>
	);
};

export default AppRouter;