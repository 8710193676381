import styled from '@emotion/styled';

interface Button {
	isClicked? : boolean;
}

export const ProductViewContainer = styled.div``;
export const ProductViewWrapper  = styled.div`
	display: flex;
	flex-direction: row;
`;

export const ChangeModeButtonContainer = styled.div`
	min-width: 80px;
`;
export const ChangeModeButtonWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	padding: 10px;
`;
export const ChangeModeButtonInner = styled.span`
	color: #ededed;
`;

export const ChangeModeButton = styled.button`
	
`;

export const AdditProductListViewContainer = styled.div``;
export const AdditProductListViewWrapper = styled.div`
	display: flex;
	flex-direction: row;
`;

export const ProductListViewContainer = styled.div`
	min-width: 300px;

`;
export const ProductListViewWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;
`;

export const ProductItemButton = styled.button``;
export const ProductItemName = styled.span<Button>`
	color: ${(props) => props.isClicked ? '#F73C61': '#ededed'};
`;
export const NotSelectProductViewContainer = styled.div``;
export const NotSelectProductViewWrapper = styled.div``;
export const NotSelectProductViewTitle = styled.span`
	color: #ededed;
`;
