import React from 'react';
import { Global, css } from '@emotion/react';

const style = css`
  @font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 400;
    src: url("./fonts/NotoSansKR-Regular.woff2") format('woff2'),
        url("./fonts/NotoSansKR-Regular.woff") format('woff'),
        url("./fonts/NotoSansKR-Regular.otf") format('truetype');
  }
  @font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 500;
    src:  url("./fonts/NotoSansKR-Medium.woff2") format('woff2'),
          url("./fonts/NotoSansKR-Medium.woff") format('woff'),
          url("./fonts/NotoSansKR-Medium.otf") format('truetype');
  }
  @font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: bold;
    src: url("./fonts/NotoSansKR-Bold.woff2") format('woff2'),
    url("./fonts/NotoSansKR-Bold.woff") format('woff'),
    url("./fonts/NotoSansKR-Bold.otf") format('truetype');
  }
  * {
    margin: 0px;
    padding: 0px;
    font-family: 'Noto Sans KR', sans-serif;
    
  }
  body {
    background-color: #fffff;
  }
  div {
    font-family: 'Noto Sans KR', sans-serif;
  }
  button {
    border: none;
    background-color: transparent;
  }
  input {
    border: none;
    font-size: 14px;
    background-color: transparent;
    outline: none;
  }
  a {
  	text-decoration-line: none;
	  color: inherit;
  }
  #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
`;

const Globalstyle = () => {
  return <Global styles={style} />;
};

export default  Globalstyle;
