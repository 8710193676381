import styled from '@emotion/styled';

export const AdditProductContainer = styled.div`
  color: #ededed;

`;
export const AdditProductWrapper = styled.div``;

export const PhotoPreviewImg = styled.img`
  witdh: 230px;
  height: 230px;
`;
export const CompanyButton = styled.button`
  border-radius: 8px;
  padding: 4px 8px;
  background-color: black;
`;
export const CompanyName = styled.span`
  color: #ededed;
`;
export const NutrientButton = styled.button`
  border-radius: 8px;
  padding: 4px 8px;
  background-color: black;
`;
export const NutrientName = styled.span`
  color: #ededed;
`;
export const TagButton = styled.button`
  border-radius: 8px;
  padding: 4px 8px;
  background-color: black;
`;
export const TagName = styled.span`
  color: #ededed;
`;

export const NutrientFactsContainer = styled.div`
  margin-bottom: 20px;
`;
export const NutrientFactsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
export const NutrientFactsItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const NutrientFactName = styled.span`
  margin-right: 8px;
`;
export const NutrientFactInput = styled.input`
  background-color: black;
`;

export const Item = styled.div``;