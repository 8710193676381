import styled from '@emotion/styled';

export const AddProductViewContainer = styled.div`
`;
export const AddProductViewWrapper = styled.div`
	padding: 10px;
`;

export const ProductContainer = styled.div`
	margin-bottom: 20px;
	display: flex;
	flex-direction: column;
`;
export const ProductTitle = styled.span`
	font-size: 16px;
	color: #ededed;
	margin-bottom: 4px;
`;

export const ListContainer = styled.div``;
export const SelectedItemContainer = styled.div``;
export const SelectedListContainer = styled.div``;
export const SelectedListWrapper = styled.div``;
export const ListItemButton = styled.button``;
export const ListItemContainer = styled.div``;
export const ListItem = styled.div``;

export const AddProductButtonContainer = styled.div``;
export const AddProductButton = styled.button``;

export const PhotoAddContainer = styled.div``;
export const PhotoAddWrapper = styled.div``;
export const PhotoAddInput = styled.input``;
export const PhotoPreview = styled.div``;
export const PhotoPreviewImg = styled.img``;
export const PhotoDeleteButton = styled.button``;