import React, { useEffect, useState } from 'react';
import { db } from '../../../../firebase';
import { 
  setDoc,
  collection,
  getDoc,
	getDocs,
  doc,
} from 'firebase/firestore';
import { 
  Photo,
  Supplement,
} from '../../../../interfaces';
import {
	addValueToArray
} from '../../../../utils';
import {
	AdditProductContainer, AdditProductWrapper, CompanyButton, CompanyName, NutrientButton, NutrientFactInput, NutrientFactName, NutrientFactsContainer, NutrientFactsItem, NutrientFactsListContainer, NutrientName, PhotoPreviewImg, TagButton, TagName,
} from './styles';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import imageCompression from 'browser-image-compression';

interface AdditProductViewProps {
	id: string;
}
interface Infos {
	 fat: string;
	 kcal: string;
	 protein: string;
	 serving: string;
	 carbohydrate: string;
	 sugars: string;
}
interface FactsInputList {
	placeholder: string;
	name: string;
	value: string
}


const AdditProductView = ({
	id
}: AdditProductViewProps) => {
	const collectionName = 'bochung_supplements';
	
  const [ name, setName ] = useState<string>('');
  const [ infos, setInfos ] = useState<Infos>({
	  fat: '',
	  kcal: '',
	  protein: '',
	  serving: '',
	  carbohydrate: '',
	  sugars: '',
  });
	
  const {fat, kcal, protein, serving, carbohydrate, sugars } = infos;
	
  const NutrientFactsList: FactsInputList[] = [
	  { placeholder: '지방', name: 'fat' , value: fat},
	  { placeholder: '열량', name: 'kcal', value: kcal },
	  { placeholder: '단백질', name: 'protein', value: protein},
	  { placeholder: '1회 제공량', name: 'serving', value: serving },
	  { placeholder: '탄수화물', name: 'carbohydrate', value: carbohydrate },
	  { placeholder: '당류', name: 'sugars', value: sugars },  
  ]
	const [unit, setUnit] = useState<string>('kg');

  const [photo, setPhoto] = useState<Photo>({url: 'url'});

  const [ nutrient, setNutrient ] = useState<string[]>([]);
  const [ allNutrientList, setAllNutrientList ] = useState<string[]>([]);

  const [ tag, setTag ] = useState<string[]>([]);
  const [ allTagList, setAllTagList ] = useState<string[]>([]);

  const [ company, setCompany ] = useState<string>('');
  const [ allCompanyList, setAllCompanyList ] = useState<string[]>([]);
	
  const [loading, setLoading] = useState<boolean>(false);
	
  const [desc, setDesc] = useState<string>('');
	const [price, setPrice] = useState<string>('');
	const [ weight, setWeight ] = useState<string>('');
	const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [previewURL, setPreviewURL] = useState<string | null>(null);

  const updateProduct = async(data: Supplement) => {
    const docRes =  await setDoc(doc(db, collectionName, id), data);
  }

  const handleCheckTag = (t: string) => {
    const updatedTagList: string[] = addValueToArray<string>(tag, t);
    setTag(updatedTagList);
  }
  const handleCheckNutrient = (n: string) => {
    const updatedNutrientList: string[] = addValueToArray<string>(nutrient, n);
    setNutrient(updatedNutrientList);
  }

  const getTagList = async() => {
    const docRef = await getDocs(collection(db, 'bochung_tags'));
    const docData: string[] = docRef.docs.map((doc) => {
      return doc.data().name;
    });

    setAllTagList(docData);
  }

  const getNutrientList = async () => {
    const docRef = await getDocs(collection(db, 'bochung_nutrients'));
    const docData: string[] = docRef.docs.map((doc) => {
      return doc.data().name;
    });

    setAllNutrientList(docData);
  }

  const getCompanyList = async () => {
    const docRef = await getDocs(collection(db, 'bochung_companies'));
    const docData: string[] = docRef.docs.map((doc) => {
      return doc.data().name;
    });

    setAllCompanyList(docData);
  }
  const onChangeFacts = (e: React.ChangeEvent<HTMLInputElement>) => {
	  const {name, value} = e.target;
	  
	  setInfos({
		  ...infos,
		  [name]: value,
	  });
  }

	const uploadPhotoToFireStorage = async () => {
    const storage = getStorage();
    const fileRef = ref(storage, uuidv4());
		await imageCompress(selectedFile!).then(async (res) => {
			const response = await uploadBytes(fileRef, res!);
			if(response !== undefined)
			{
				const fileURL = await getDownloadURL(fileRef);
				additProduct(fileURL);
  	  }
		});  
  }
  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file: File = e.target.files![0];

		setSelectedFile(file);

    const reader = new FileReader();

    reader.onload = () => {
      setPreviewURL(reader.result as string);
    }
    reader.readAsDataURL(file as Blob);
  }

  const handlePhotoDelete = () => {
    setSelectedFile(null);
    setPreviewURL(null);
  }

	const imageCompress = async (file: File) => {
		const options = {
			maxSizeMB: 0.2,
			maxWidthOrHeight: 200,
			useWebWorker: true,
		}

		try {
			return await imageCompression(file, options);
		} catch (error) { console.log(error) }
	}

  const getProduct = async() => {
	const docRef = doc(db, collectionName, id);
	const docSnap = await getDoc(docRef);
	
	
	  if(docSnap.exists()) {
		  const docData = docSnap.data();
		  
		  setName(docData.name);
		  setInfos(docData.infos);
		  setNutrient(docData.nutrients);
		  setCompany(docData.company);
		  setTag(docData.tags);
		  setDesc(docData.desc);
		  setPhoto(docData.photo);
		  setPrice(docData.price);
		  setWeight(docData.weight);
			setPreviewURL(docData.photo.url);
			setUnit(docData.unit);
		  getTagList();
	  	getCompanyList();
	 	  getNutrientList();
			setLoading(true);
	 }
	  
	
	  
  }
  const additProduct = (fileURL: string) => {
		const data: Supplement = {
      name: name,
      company: company,
      infos: {
				fat:parseFloat(infos.fat),
				kcal: parseFloat(infos.kcal),
				protein: parseFloat(infos.protein),
				serving: parseFloat(infos.serving),
				carbohydrate: parseFloat(infos.carbohydrate),
				sugars: parseFloat(infos.sugars),
			},
      tags: tag,
      photo: {
				url: fileURL,
			},
      nutrients: nutrient,
			price: parseInt(price),
			weight: parseFloat(weight),
			unit: unit,
    }
	
    updateProduct(data);
	}

  const onClick = () => {
    if(photo.url !== previewURL) uploadPhotoToFireStorage();
		setLoading(false);
  }
  
  
	
	useEffect(() => {
		getProduct();
	}, [id, loading]);
	
  return (
    <AdditProductContainer>
		  {
			  !loading ?
				  <div>loading...</div>
			  :
			  <AdditProductWrapper>
				  <div>
					  <div>이름</div>
					  <input 
						  placeholder="이름"
						  onChange={(e) => setName(e.target.value)}
						  value={name}
					  />
					</div>
				  <div>
					  <div>가격</div>
						  <input 
							  placeholder="가격"
							  onChange={(e) => setPrice(e.target.value)}
							  value={price}
						  />
					  </div>
				  <div>
					  <div>용량</div>
						  <input 
							  placeholder="용량"
							  onChange={(e) => setWeight(e.target.value)}
							  value={weight}
						  />
							<button onClick={() => setUnit('kg')}>kg</button>
							<button onClick={()=> setUnit('kg')}>ml</button>
					  </div>
					<div>
					  <div>회사</div>
					  <div>
						{
						  allCompanyList.map((c) => (
							<CompanyButton key={c} onClick={() => setCompany(c)}>
							  <CompanyName>{c}</CompanyName>
							</CompanyButton>
						  ))
						}
					  </div>

					  <div>
							<CompanyName>선택한 회사</CompanyName>
						<div>
						  {company}
						</div>
					  </div>
					</div>

					<NutrientFactsContainer>
					  <div>영양정보</div>
					  <NutrientFactsListContainer>
						  {
							NutrientFactsList.map((i) => 
							<NutrientFactsItem>
								<NutrientFactName>{i.placeholder}</NutrientFactName>
								<NutrientFactInput 
									key={i.name}
									placeholder={i.placeholder} 
									onChange={onChangeFacts} 
									name={i.name} 
									value={i.value}
								/>
								</NutrientFactsItem>
							)
						  }
					  </NutrientFactsListContainer>
					</NutrientFactsContainer>

				  <div>
					<div>성분</div>
					<div>
					  {
						allNutrientList.map((nutrient) => (
						  <NutrientButton key={nutrient} onClick={() => handleCheckNutrient(nutrient)}>
								<NutrientName>{nutrient}</NutrientName>
						  </NutrientButton>
						))
					  }
					</div>
					<div>
					  <div>
						<span>선택한 성분</span>
						<div>
						  {
							nutrient.map((n) => (
							  <div key={n}>
									<NutrientName>{n}</NutrientName>
								</div>
							))
						  }
						</div>
					  </div>
					</div>

				  </div>
				  <div>
						<div>사진</div>
						<div>
							<input type='file' accept='image/*' onChange={onFileChange}/>
							<div>
								{previewURL && <PhotoPreviewImg src={previewURL} alt="PrevireImg"/>}
								<button onClick={handlePhotoDelete}>삭제</button>
            </div>
          </div>
				  </div>
				  <div>
					<div>태그</div>
					<div>
					  {
						allTagList.map((tag) => (
						  <TagButton key={tag} onClick={() => handleCheckTag(tag)}>
								<TagName>{tag}</TagName>
						  </TagButton>
						))
					  }
					</div>
					<div>
					  <div>
						<span>선택한 태그</span>
						<div>
						  {
							tag.map((t) => (
							  <div key={t}>
									<TagName>{t} ||</TagName>
								</div>
							))
						  }
						</div>
					  </div>
					</div>
				  </div>
				  <div>
					<button onClick={() => onClick()}>
					  <span>상품 수정하기</span>
					</button>
				  </div>
			  </AdditProductWrapper>
		  }
	</AdditProductContainer>
  );
};

export default AdditProductView;