import styled from '@emotion/styled';

export const KaKaoLoginContainer = styled.div`
 padding: 0 16px;
`;
export const KakaoLoginWrapper = styled.div`

`;
export const KakaoLink = styled.a`
  background-color: #FAE54D;
  display: flex;
  justify-content: center;
  border-radius: 12px;
`;
export const KakaoLinkImg = styled.img``;