import styled from '@emotion/styled';

interface Scrolled {
	scrolled? : boolean;
}
export const HeaderContainer = styled.header<Scrolled>`
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
	position: ${(props) => props.scrolled ? 'fixed' : 'block'};
	background-color: ${props => props.theme.colors.headerBackground};
`;

export const HeaderWrapper = styled.div<Scrolled>`
	width: 100%;
	display: flex;
	flex-direction: ${(props) => props.scrolled ? 'row' : 'column'};
	justify-content: ${(props) => props.scrolled ? 'space-between' : ''};
	padding: 16px 16px 0 16px;
	
`;
export const LogoImgContainer = styled.div``;
export const LogoImg = styled.img`
	height: 32px;
	width: 32px;
`;

export const HeaderButtonsContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;
export const LogoButtonContainer = styled.button<Scrolled>`
	margin-bottom: ${(props) => props.scrolled ? '12px' : '24px'};
	display: flex;
	justify-content: space-between;
`;
export const SignButtonContainer = styled.div<Scrolled>`
	display: ${(props) => props.scrolled ? 'none' : 'block'};
`;
export const SignButton = styled.button``;
export const SignButtonTitle = styled.span`
	color: #EBEBEB;
	font-size: 16px;
`;