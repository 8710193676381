import styled from '@emotion/styled';


export const DetailContainer = styled.div`
	margin-top: 68px;
	flex:1;
`;
export const DetailWrapper = styled.div`
	padding: 0 16px;
	flex-direction: column;
`;
export const SupplementNameConatiner = styled.div`
	margin-bottom: 20px;
`;
export const SupplementName = styled.span`
	line-height: 140%;
	font-size: 20px;
	font-seight: medium;
`;
export const CompanyNameContainer = styled.div``;
export const ProductDescriptionContainer = styled.div``;

export const NutrientsContainer = styled.div`
	display: flex;
	justify-content: flex-start;
	margin-bottom: 20px;
`;
export const NutrientsWrapper = styled.div`
`;
export const NutrientListInner = styled.div`
	margin-top: 10px;
	display: flex;
	gap: 10px;
`;
export const PhotoConatiner = styled.div`
	margin-right: 16px;
`;
export const Photo = styled.img`
	border-radius: 4px;
	width: 161px;
	height: 100%;
`;
export const InfoContainer = styled.div`
	display: flex;
	flex-direction: row;
	margin-bottom: 20px;
`;
export const Infos = styled.ul`
	color: black;
	width: 100%;
	padding: 6px 0;
	display: flex;
	gap: 8px;
	flex-direction: column;
`;
export const InfoTitle = styled.span`
	color: #8D8D8D;
	font-size: 14px;
`; 
export const InfoContent = styled.span`
	font-size: 14px;
`;
export const InfoItem = styled.li`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: stretch;
	color: ${props => props.theme.colors.baseText};
	line-height: 118%;

`;
export const RelatedTagContainer = styled.div`
	display: flex;
	justify-content: flex-start;
	width: 100%;
	flex-direction: column;
	
`;
export const RelatedTagWrapper = styled.div`
	padding: 10px;
`;

export const RelatedTagList = styled.div`
	flex-direction: row;
	flex-wrap: wrap;
	margin-top: 10px;
`;
export const DescContainer = styled.div`
	display: flex;
	justify-content: flex-start;
	margin-top: 20px;
	
`;
export const DescWrapper = styled.div`

`;
export const DescInner = styled.span`
	margin-top: 10px;
	display: block;
	
	line-height: 150%;
`;

export const BoldText18 = styled.span`
	font-weight: bold;
	font-size: 18px;
`;
export const BoldText16 = styled.span`
	font-weight: bold;
	font-size: 16px;
`;

export const SmallItemContainer = styled.button`
	border-radius: 4px;
	padding: 4px 8px;
	background-color: #36383C;
`;
export const NutrientName = styled.span`
	font-size: 14px;
	font-weight: regular;
	line-height: 140%;
	color: #ebebeb;
`;

export const AdsContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	
	margin-top: 20px;
`;

export const AdsContainerTitleContainer = styled.div`
	line-height: 120%;
`;
export const AdsContainerTitleInner = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`;
export const AdsContainerTitle = styled.span`
	display: block;
	font-size: 12px;
`;

export const CompanyContainer = styled.div`
	width: 100%;
	display: flex;
	margin-bottom: 4px;
`;
export const CompanyWrapper = styled.div`
	
`;
export const CompanyTitle = styled.div`
	display: flex;
	
	flex-direction: row;
	align-items: center;
	line-height: 140%;
	
`;
export const CompanyHompageLink = styled.div`
	font-size: 16px;
	color: ${props => props.theme.colors.mainColor};
`;

export const PriceContainer = styled.div`
	width: 100%;
	display: flex;
`;
export const PriceWrapper = styled.div`
`;
export const Weight = styled.span`
	font-size: 14px;
	font-weight: regular;
	line-height: 140%;
`;
export const Price = styled.span`
	font-size: 20px;
	color: #F73C61;
	font-weight: medium;
	line-height: 140%;
	margin-right: 4px;
`;
export const SubPrice = styled.span`
	font-size: 14px;
	color: #F73C61;
	font-weight: regular;
	line-height: 140%;
`;

export const DetailPageTopBarContainer = styled.header`
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	background-color: ${props => props.theme.colors.headerBackground};
`;
export const DetailPageTopBarWrapper = styled.div`
	padding: 18px 16px;
	display: flex;
	flex-direction: row;
`;
export const BackButton = styled.button`
	width: 32px;
	height: 32px;
`;
export const BackButtonImg = styled.img``;
export const DetailPageTopBatTitle = styled.span`
	font-size: 18px;
	color: ${props => props.theme.colors.title};
	margin: auto;
`;
export const ExportButtonContainer = styled.div`
`;
export const ShareButton = styled.button`
`;
export const ShareButtonImg = styled.img`
	width: 28px;
	height: 28px;
	margin: auto;
`;
export const SkeletonNutrientsWrapper = styled.div`
	display: flex;
	flex-direction: row;
	gap: 10px;
`;