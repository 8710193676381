import {
	HeaderContainer,
	LogoButtonContainer,
	HeaderWrapper,
	LogoImg,
	LogoImgContainer,
	HeaderButtonsContainer,
} from './styles';
import { useNavigate } from 'react-router-dom';
import { RecommendationBar } from '../recommedationBar';
import Logo from '../../utils/imgs/favicon.png';

const Header = ({
	scrolled
} : {
	scrolled: boolean,
}) => {
	const navigate = useNavigate();
	return (
		<HeaderContainer scrolled={scrolled}>
			<HeaderWrapper scrolled={scrolled}>
				<HeaderButtonsContainer>
					<LogoButtonContainer onClick={() => navigate('/')} scrolled={scrolled}>
						<LogoImgContainer>
							<LogoImg src={Logo} alt='logo_img' />
						</LogoImgContainer>
					</LogoButtonContainer>
				</HeaderButtonsContainer>
				<RecommendationBar scrolled={scrolled}/>
			</HeaderWrapper>
		</HeaderContainer>
	);
};
export default Header;