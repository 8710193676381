import styled from '@emotion/styled';

export const FooterContainer = styled.footer`
	background: #2F3035;
	color: #C0C0C2;
	
	width: 100vw;
	flex-shrink: 0;
`;
export const FooterInner = styled.div`
	
	padding: 12px 0 20px 0;
`;

export const Contact = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;
export const ContactButton = styled.button`
	font-size: 12px;
	font-weight: 400;
	color: white;
	text-decoration-line: underline;
`;
export const ContactInfo = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	margin-top: 32px;

	font-weight: 300;
	font-size: 10px;

`;