import { head } from "lodash";

const theme = {
  colors: {
    //background
    background: '#FFFFFF',
    headerBackground: '#FFFFFF',
    searchBarBackground: '#f5f5f5',

    //text
    title: '#000000',
    subTitle: '#333333',
    baseText: '#000000',
    headerLogoText: '#000000',
    mainColor: '#F73C61',

    //card
    cardBackground: '#f9f9f9',
    cardTitle: '#000000',
    cardText: '#333333',

    //button
    buttonOnClickBackground: '#F73C61',
    buttonBaseBackground: '#f9f9f9',

    buttonOnClickText: '#FFFFFF',
    buttonBaseText: '#000000',

    //border
    searchBarBorder: '#f5f5f5',
    
  },
  size: {
    //header
    headerHeight: '64px',
    headerPadding: '16px',
  },
  fonts: {
    primary: 'Roboto, sans-serif',
    secondary: 'Montserrat, sans-serif',
  },
  fontSizes: {
    small: '0.8rem',
    medium: '1rem',
    large: '1.2rem',
    headerTitle: '1.5rem',
  },
};

export default theme;