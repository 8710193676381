import styled from '@emotion/styled';

export const Container = styled.div`
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;


export const ListContainer = styled.div`
 
`;
export const ListTitleContainer = styled.div`
 margin-bottom: 12px;
`;
export const ListTitle = styled.span``;
export const List = styled.ul``;
export const Item = styled.li``;
export const ItemName = styled.span``;

export const FormContainer = styled.div``;
export const FormInput = styled.input``;
export const FormNameContainer = styled.div``;
export const FormName = styled.span``;
export const FormSummaryContainer = styled.div``;
export const FormSummary = styled.span``;
export const FormDescContainer = styled.div``;
export const FormDesc = styled.span``;

export const SummitContainer = styled.div``;
export const SummitButton = styled.button`
  padding: 4px 8px;
  border-radius: 4px;
  background-color: #B4B4B4;
`;
export const SummitTitle = styled.span`
  color: white;  
`;


export const FormImageContainer = styled.div``;
export const FormImagePreview = styled.div``;
export const FormImageInput = styled.input``;

