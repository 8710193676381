import styled from '@emotion/styled';

export const ReviewItemContainer = styled.li`
  list-style-type: none;
`;
export const ReviewItemWrapper = styled.div`
  display: flex;
  flex-direction: column;

  border-bottom: 1px solid #36383C;
  padding-bottom: 40px;
`;

export const ReviewItemUserContainer = styled.div`

`;
export const ReviewItemUserNameConatiner = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`;
export const NickName = styled.span`
  font-weight: bold;
  font-size: 16px;
`;
export const UserImageContainer = styled.div`
  width: 40px;
  height: 40px;

  background-color: white;
  border-radius: 100px;
`;
export const UserImage = styled.img``;
export const ReviewItemUserProfileConatiner = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ReviewItemUserPersonalTagListContainer = styled.div``;

export const ReviewItemContentsContainer = styled.div`

`;

export const ReviewItemScoreContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;\

  margin-bottom: 20px;
`;
export const ReviewItemTimeStempContainer = styled.div`
`;

export const ReviewItemContentContainer = styled.div``;

export const ReviewItemRecommendContainer = styled.div``;

export const ReviewItemLikeContainer = styled.div`
  border: 1px solid #36383C;
  border-radius: 8px;
`;
export const ReviewItemLikeButton = styled.button``;
export const ReviewItemLikeButtonImg = styled.img``;
export const ReviewItemLikeCount = styled.span``;
export const ReviewItemReportContainer = styled.div``;