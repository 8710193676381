import styled from '@emotion/styled';

interface ButtonProps {
  clicked?: boolean;
}
interface ActiveState {
  isActive?: boolean;
	scrolled? : boolean;
}


//base
export const Container = styled.div<ActiveState>`
	width: 100vw;
	margin-bottom : 96px;
	flex:1;
	margin-top: ${(props) => props.scrolled ? '142px' : '0'};
	background-color: ${props => props.theme.colors.background};
`;

export const Wrapper = styled.div`
	flex-direction: column;
	padding: 0 16px;
`;

export const Contents = styled.div`
	flex-direction: column;
`;


//introduce nutrient
export const IntroduceNutrientContainer = styled.div`
	width: 100%;
	margin-top: 33px;
`;
export const IntroduceNutrientTitleContainer = styled.div`
	width: 100%;
	margin-bottom: 8px;
`;
export const IntroduceNutrientTitle = styled.p`
	font-size: ${props => props.theme.fontSizes.large};
	font-weight: 500;
	color: #000000;
	line-height: 140%;
`;
export const IntroduceNutrientButtonContainer = styled.div`
	display: flex;
	flex-flow: row wrap;
	margin-bottom: 12px;
`;
export const NutrientButtonConatainer = styled.div`
	width: 100%;
	display: flex;
`;
export const NutrientButton = styled.button<ButtonProps>`
	border-radius: 4px;
	background-color: ${(props) => props.clicked ? props.theme.colors.buttonOnClickBackground : props.theme.colors.buttonBaseBackground};
	color: ${(props) => props.clicked ? props.theme.colors.buttonOnClickText : props.theme.colors.buttonBaseText};
	padding: 4px 8px;
	margin-right: 12px;
`;
export const NutrientButtonTitle = styled.span`
	font-size: ${props => props.theme.fontSizes.medium};
	font-weight: medium;
	line-height: 140%;
`;

//item list
export const ListItemContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;
export const ListItemWrappper = styled.div`
`;
export const ListItemImage = styled.div`
	width: 73px;
	height: 73px;
	background-color: #F73C61;
`;
export const ListItemInfoArea = styled.div`
	width: 100%;
`;
export const ListItem = styled.div`
	display: flex;
	flex-direction: row;
	margin-bottom: 16px;
	width: 100%;
`;

//nutrient description
export const DescContainer = styled.div`
	width: 100%;
	margin-bottom: 8px;
	
`;
export const DescWrapper = styled.div``;
export const DescInnerContainer = styled.div`
	padding: 8px;
	border-radius: 4px;
	background-color: ${props => props.theme.colors.cardBackground};
	margin-top: 7px;
	margin-bottom: 24px;
	height: 60px;
`;
export const DescText = styled.span`
	font-size: ${props => props.theme.fontSizes.small};
	line-height: 140%;
	color: ${props => props.theme.colors.cardText};
`;


export const DescTitle = styled.span`
	font-size: ${props => props.theme.fontSizes.medium};
	line-height: 140%;
	font-weight: medium;
	color: ${props => props.theme.colors.baseText};
`;
export const DescContent = styled.span``;

export const NoListComponentContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	
`;
export const NoListComponentWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`;

export const SummeryContainer = styled.div`
	width: 100%;
	padding-top: 10px;
`;

export const SummeryNutrientFacts = styled.div`
	display: flex;
	flex-column: row;
`;
export const SummeryContaineNutrients = styled.div``;
export const SummeryTags = styled.div``;

export const PurposeResetButtonContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;
export const PurposeResetButtonWrapper = styled.div`
	padding: 10px;
`;
export const PurposeResetButton = styled.button`
	padding: 10px;
	background-color: #F73C61;
	color: white;
	font-weight: bold;
	
	border-radius: 8px;
`;

export const SortButtonContainer = styled.div`
	width: 100%;
	margin-bottom: 16px;
`;
export const SortButtonWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
`;
export const SortButton = styled.button`
	display: flex;
	flex-direction: row;
	padding: 4px 8px;
	border-radius: 8px;
	background-color: ${props => props.theme.colors.buttonBaseBackground};

`;
export const SortButtonTitle = styled.span`
	color: ${props => props.theme.colors.buttonBaseText};
	font-size: 14px;
	font-weight: regular;
	line-height: 140%;
	margin-right: 4px;
`;
export const SortButtonImgContainer = styled.div`
	width: 16px;
	height: 16px;
`;
export const SortButtonImg = styled.img``;

export const ListLoadingContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;