import React, {useEffect, useState} from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import {
	DetailPageTopBarContainer,
	DetailPageTopBarWrapper,
	BackButton,
	BackButtonImg,
	DetailPageTopBatTitle,
	ShareButton,
	ShareButtonImg,
} from './styles';
import { db } from '../../firebase';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc, DocumentSnapshot  } from "firebase/firestore";
import BackButtonIcon from '../../utils/imgs/arrow_back_ios.svg';
import DetailView from './DetailView';
import Loading from './Loading';
import ShareButtonIcon from '../../utils/imgs/launch.svg';
import MetaTag from '../../components/meta';
import { Footer } from '../../components';

interface SupplementInfoProps{
	kcal: number;
	protein: number;
	fat: number;
	serving: number;
	carbohydrate: number;
	sugars: number;
}
interface CompanyProps {
	name: string;
	url: string;
}
interface SupplementProps {
	id: string;
	company: string;
	name: string;
	photo: {
		url: string;
	};
	nutrients: string[];
	tags: string[];
	infos: SupplementInfoProps;
	desc: string;
	price: number;
	weight: number;
	link: string;
	unit: string
}

const DetailPage = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const currentLocation = location.pathname;

	const [ searchParams ] = useSearchParams();
  const id = searchParams.get('id') || ' ';
	const [ supplement, setSupplement ] = useState<SupplementProps>();
	const [ company, setCompany ] = useState<CompanyProps>({
		name: '',
		url: ''
	});
	const [ loading, setLoading ] = useState<boolean>(false);

	const fetchData = async () => {
		const docRef: any = doc(db, "bochung_supplements", id ?? "");
		const docSnap: DocumentSnapshot  = await getDoc(docRef);
		if (docSnap.exists()) {
			const d: any =  { ...docSnap.data() }
			setSupplement(d);
			getComapanyData(d.company);
			setLoading(!loading);
		} else {
			console.log("No such document!");
		}
	};
	
	const getComapanyData = async (c: string) => {
		const docRef: any = doc(db, "bochung_companies", c ?? "");
		const docSnap: DocumentSnapshot  = await getDoc(docRef);
		if (docSnap.exists()) {
			const d: any =  { ...docSnap.data() }
			setCompany(d);
			setLoading(!loading);
		} else {
			console.log("No such document!");
		}
	}
	const hadleShareButton = async () => {
		await navigator.share({
			title: supplement?.name,
			url: `${currentLocation}?id=${id}`,
		});
	}

	const handleBackButton = () => {
		const doesAnyHistoryEntryExist = location.key !== "default";
		
		if(doesAnyHistoryEntryExist) navigate(-1);
		else navigate('/');
	}

	useEffect(() => {
		if(!loading) fetchData();

	 }, []);
	
	return ( 
		<>
			<MetaTag
				title={supplement?.name}
				url={`https://bo-chung.com${location.pathname}?id=${id}`}
				description={supplement?.desc}
			/>
			<DetailPageTopBarContainer>
				<DetailPageTopBarWrapper>
					<BackButton onClick={handleBackButton}>
						<BackButtonImg src={BackButtonIcon}/>
					</BackButton>
					<DetailPageTopBatTitle>상품</DetailPageTopBatTitle>
						<ShareButton onClick={hadleShareButton}>
							<ShareButtonImg src={ShareButtonIcon} alt='share_icon'/>
						</ShareButton>
				</DetailPageTopBarWrapper>
			</DetailPageTopBarContainer>
			{
				loading ?
					<>
						<DetailView
							supplement={supplement!}
							company={company}	
						/>
					</>
					:
					<></>
					// <Loading/>
			}
			<Footer/>
		</>
	);
};

export default DetailPage;