import React, {useEffect, useState} from 'react';
import {
	SearchBarConatiner,
	SearchBarInput,
	SearchBarWrapper,
	SearchButtonImg,
} from './styles';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SearchButtonIcon from '../../utils/imgs/search_button_icon.svg';

const SearchBar = () => {
const [ searchParams ] = useSearchParams();
  const question = searchParams.get('question') || '';

	const [inputValue, setInputValue] = useState<string>(question);

	const navigate = useNavigate();
	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => { 

		navigate(`/search?question=${e.target.value}`); 
		setInputValue(e.target.value);
	}

	return (
		<SearchBarConatiner>
			<SearchBarWrapper>
				<SearchButtonImg src={SearchButtonIcon}/>
				<SearchBarInput 
					onChange={onChange} 
					placeholder="검색어를 입력해보세요" 
					value={inputValue}
				/>
			</SearchBarWrapper>
		</SearchBarConatiner>
	);
};

export default SearchBar;