import React from 'react';
import {
	SupplementName,
	ListItemInfoArea,
	SummeryContainer,
	SummeryNutrientFacts,
	SupplementPrice,
	SummeryInner,
	PhotoContainer,
	InfoText,
	Photo,
} from './styles';

interface SupplementInfoProps{
	kcal: number;
	protein: number;
	fat: number;
	serving: number;

}
interface CompanyProps {
	id: string;
	name: string;
}
interface SupplementProps {
	id: string;
	company: CompanyProps;
	name: string;
	photo: {
		url: string;
	};
	nutrients: any[];
	tags: string[];
	infos: SupplementInfoProps;
	desc: string;
	price: number;
	weight: number;
	unit: string;
}

const SupplementListItem = ({
	supplement,
}: {
	supplement: SupplementProps,
}) => (
	<ListItemInfoArea>
		<SummeryContainer>
			<PhotoContainer>
				<Photo src={supplement.photo.url} alt="img"/>
			</PhotoContainer>
			<SummeryInner>
				<SupplementName>{supplement.name}</SupplementName>
				<SupplementPrice>{supplement.price.toLocaleString('ko-KR')}원</SupplementPrice>
				<SummeryNutrientFacts>
					<InfoText>용량: {supplement.weight.toLocaleString('ko-KR')}{supplement.unit || 'kg'}</InfoText>
					<InfoText>단백질 함유량: {supplement.infos.protein}g</InfoText>
					<InfoText>열량: {supplement.infos.kcal}kcal</InfoText>
				</SummeryNutrientFacts>
			</SummeryInner>
		</SummeryContainer>
	</ListItemInfoArea>
);

export default SupplementListItem;