import styled from '@emotion/styled';

export const ListItemInfoArea = styled.div`
	width: 100%;
`;

export const SummeryContainer = styled.div`
	display: flex;
	flex-direction: row;
	background-color: ${props => props.theme.colors.cardBackground};
	padding: 16px 14px;
	border-radius: 4px;

`;
export const SummeryInner = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 14px;
	color: 666666;
`;

export const PhotoContainer = styled.div`
	min-width: 103px;
	height: 100%;

	background-color: transparent;
	border-radius: 4px;
`;
export const Photo = styled.img`
	width: 103px;
	height: 100%;
	border-radius: 4px;
	loading: lazy;
`;

export const SupplementName = styled.span`
	font-size: ${props => props.theme.fontSizes.medium};
	font-weight: medium;
	color: ${props => props.theme.colors.baseText};
	margin-bottom: 2px;
	line-height: 140%;
`;
export const SupplementPrice = styled.span`
	font-size: ${props => props.theme.fontSizes.large};
	font-weight: medium;
	color: #000000;
	line-height: 140%;
`;
export const SummeryNutrientFacts = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 14px;
`;
export const InfoText = styled.span`
	font-size: ${props => props.theme.fontSizes.small};
	line-height: 140%;
`;

export const SkeletonWrapper = styled.div`
	flex:1;
`;
