import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { 
	getAuth, 
	signOut,
	setPersistence,
	signInWithEmailAndPassword, 
	browserSessionPersistence,
	onAuthStateChanged,
} from "firebase/auth";
import { Route, Routes, useNavigate } from 'react-router-dom';

import ProducView from './product';
import NutrientView from './nutrient';
import TagView from './tag';
import CompanyView from './company';

import {
	AdminPageContainer,
	AdminPageWrapper,
	AdminPageCategoryButtonsContainer,
	AdminPageCategoryButtonsWrapper,
	AdminViewContainer,
	AdminViewWrapper,
	AdminPageCategoryButton,
	AdminLoginPageContainer,
	AdminLoginPageWrapper,
	LoginInputContainer,
	LoginInputTitle,
	LoginInput,
	SigninButton,
	SigninButtonContainer,
	SigninInner,
	
} from './styles';

interface AuthAdminProps {
	setAuth: (state: boolean) => void; 
}
const AuthAdmin = ({
	setAuth,
}: AuthAdminProps) => {
	const [email, setEmail] = useState<string>('');
	const [password, setPassword] = useState<string>('');
	const auth = getAuth();

	const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      signIn();
    }
  };

	const signIn = () => {
		

		signInWithEmailAndPassword(auth, email, password)
		  .then((userCredential) => {
			// Signed in
			const user = userCredential.user;
			if(user !== null) {
				alert('로그인 성공');
				setAuth(true);
			}
		})
		  .catch((error) => {
			const errorCode = error.code;
			const errorMessage = error.message;
		});
	}
	
	return (
		<AdminLoginPageContainer>
			<AdminLoginPageWrapper>
				<LoginInputContainer>
					<LoginInputTitle>email</LoginInputTitle>
					<LoginInput 
					placeholder="email" 
					value={email} 
					onChange={(e: any) => setEmail(e.target.value)}
				/>
				</LoginInputContainer>
				<LoginInputContainer>
					<LoginInputTitle>password</LoginInputTitle>
					<LoginInput 
					placeholder="password" 
					value={password} 
					onChange={(e: any) => setPassword(e.target.value)} 
					type='password'
				/>
				</LoginInputContainer>
				<SigninButtonContainer>
					<SigninButton onClick={signIn}>
						<SigninInner>sign in</SigninInner>
					</SigninButton>
				</SigninButtonContainer>
			</AdminLoginPageWrapper>
		</AdminLoginPageContainer>
	);
}

const AdminPage = () => {
	const [isAuth, setIsAuth] = useState<boolean>(false);
	const navigate = useNavigate();
	const auth = getAuth();
	
	const logOut = () => {
		signOut(auth).then(() => {
		  setIsAuth(false);
		}).catch((error) => {
		  // An error happened.
		});
	}
	
	const authPersistence = () => {
		
		setPersistence(auth, browserSessionPersistence)
		  .then(() => {
			 onAuthStateChanged(auth, (user) => {
				 if(user) setIsAuth(true);
				 else setIsAuth(false);
			 });
		  })
		  .catch((error) => {
			// Handle Errors here.
			const errorCode = error.code;
			const errorMessage = error.message;
		  });
	}
	
	useEffect(() => {
		authPersistence();
	}, [])

	return (
		<AdminPageContainer>
			<AdminPageWrapper>
				<AdminPageCategoryButtonsContainer>
					<AdminPageCategoryButtonsWrapper>
						<AdminPageCategoryButton onClick={() => navigate('product')}>제품 등록하기</AdminPageCategoryButton> 
						<AdminPageCategoryButton onClick={() => navigate('nutrient')}>성분 등록하기</AdminPageCategoryButton>
						<AdminPageCategoryButton onClick={() => navigate('tag')}>태그 등록하기</AdminPageCategoryButton>
						<AdminPageCategoryButton onClick={() => navigate('company')}>회사 등록하기</AdminPageCategoryButton>
					</AdminPageCategoryButtonsWrapper>
					<button onClick={logOut}>log out</button>
				</AdminPageCategoryButtonsContainer>
				
				<AdminViewContainer>
					<AdminViewWrapper>
						<Routes>
							<Route path='product' element={<ProducView/>}/>
							<Route path='nutrient' element={<NutrientView/>}/>
							<Route path='tag' element={<TagView/>}/>
							<Route path='company' element={<CompanyView/>}/>
						</Routes>
					</AdminViewWrapper>
				</AdminViewContainer>
			</AdminPageWrapper>
		</AdminPageContainer>
	);
};

export default AdminPage;
