import styled from '@emotion/styled';

export const AdminPageContainer = styled.div`
	width: 100vw;
	flex:1;
`;
export const AdminPageWrapper = styled.div`
	display: flex;
	flex-direction: row;
	
	padding: 10px;
`;

export const AdminPageCategoryButtonsContainer = styled.div`
	border: 1px solid black;
	min-width: 100px;
	display: flex;
	flex-direction: column;
	gap: 20px;
`;
export const AdminPageCategoryButtonsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 10px;
	gap: 16px;
`;
export const AdminPageCategoryButton = styled.button`
	color: black;
	margin-bottom: 12px;
`;
export const AdminViewContainer = styled.div`
	
`;
export const AdminViewWrapper = styled.div``;



export const AdminLoginPageContainer = styled.div`
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
	flex:1;
`;
export const AdminLoginPageWrapper = styled.div`
	padding: 16px;
	background-color: #36383C;
	border-radius: 4px;
`;

export const LoginInputContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 20px;
	gap: 16px;
	width: 300px;
`;
export const LoginInputTitle = styled.span`
	color: #ededed;
`;
export const LoginInput = styled.input`
	border: 1px solid #ededed;
	border-radius: 8px;
	padding: 4px 8px;

`;

export const SigninButtonContainer = styled.div`
	display: flex;
	justify-content: center;
`;
export const SigninButton = styled.button`
	background-color: #F73C61;
	padding: 4px;
	border-radius: 8px;

`;
export const SigninInner = styled.span`
	color: #ededed;
`;