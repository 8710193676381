import React from 'react';
import {hydrate, render}from 'react-dom';
import { AppRouter } from './routes';
import { BrowserRouter as Router } from 'react-router-dom';
import GlobalStyle from './style/GlobalStyle';
import { HelmetProvider } from 'react-helmet-async';
import { ThemeProvider } from '@emotion/react';
import theme from './style/theme';

const rootElement = document.getElementById("root");

if(rootElement?.hasChildNodes()) {
	hydrate(
		<React.StrictMode>
			<HelmetProvider>
				<ThemeProvider theme={theme}>
					<GlobalStyle/>
					<Router>
						<AppRouter/>
					</Router>
				</ThemeProvider>
			</HelmetProvider>
		</React.StrictMode>, rootElement
	)
}
else {
	render(
		<React.StrictMode>
			<HelmetProvider>
				<ThemeProvider theme={theme}>
					<GlobalStyle/>
					<Router>
						<AppRouter/>
					</Router>
				</ThemeProvider>
			</HelmetProvider>
		</React.StrictMode>, rootElement
	)
}