import React from 'react';
import { 
  CloseButton,
  CloseButtonImage,
  Container,
  Dummy,
  ScreenBlock,
  SortButtons, 
  SortItemTitle, 
  SortListItem, 
  Title, 
  TitleBar, 
  Wrapper 
} from './styles';
import CloseButtonImg from '../../../utils/imgs/Vector.svg';

interface SortType {
	index: string;
	title: string;
}
const SortPopup = ({
  sortList,
  handleSort,
  onCloseButton,
  state,
  currentSortType
}: {
  sortList: SortType[],
  handleSort: (s: SortType) => void,
  onCloseButton: (s: boolean) => void,
  state: boolean,
  currentSortType: SortType,
}) => (
    <ScreenBlock isActive={state}>
      <Container>
        <Wrapper isActive={state}>
          <TitleBar>
            <Dummy/>
            <Title>정렬</Title>
            <CloseButton onClick={() => onCloseButton(false)}>
              <CloseButtonImage src={CloseButtonImg} alt='close_button_img'/>
            </CloseButton>
          </TitleBar>
          <SortButtons>
            {
              sortList.map((item) => (
                <SortListItem key={item.index} onClick={() => handleSort(item)}>
                  <SortItemTitle 
                    isActive={item.title === currentSortType.title ? true : false}
                  >
                    {item.title}
                  </SortItemTitle>
                </SortListItem>
              ))
            }
          </SortButtons>
        </Wrapper>
      </Container>
    </ScreenBlock>
  );

export default SortPopup;