import styled from '@emotion/styled';

interface ActiveState {
  isActive?: boolean;
}
interface Cliked {
  isActive?: boolean;
}

export const ScreenBlock = styled.div<ActiveState>`
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.5);
  position: fixed;
  top: 0;

  display: ${(props) => props.isActive ? 'block' : 'none'};`;
export const Container = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0px;
`;
export const Wrapper = styled.div<ActiveState>`
  display: ${(props) => props.isActive ? 'block' : 'none'};
  background-color: ${props => props.theme.colors.background};
  padding: 16px 24px 45px 24px;
  border-radius: 16px 16px 0 0;
`;

export const TitleBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 24px;
`;
export const Title = styled.span`
  color: ${props => props.theme.colors.baseText};  
  font-size: 18px;
  font-weight: medium; 
`;
export const CloseButton = styled.button`
  width: 32px;
  height: 32px;
`;
export const CloseButtonImage = styled.img`
  width: 18px;
  height: 18px;
  margin: auto;
`;

export const SortButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
export const SortListItem = styled.button`
  display: flex;
  justify-content: flex-start;
`;
export const SortItemTitle = styled.span<Cliked>`
  font-size: 16px;
  font-weight: medium; 
  color:  ${(props) => props.isActive ? '#F73C61' : '#ededed'};
  line-height: 140%;

`;
export const Item = styled.li``;

export const ListConatiner = styled.ul`
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 60vh;
  gap: 16px;
`;

export const Dummy = styled.div`
  width: 32px;
  height: 32px;
`;