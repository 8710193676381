import React, { useEffect, useState } from 'react';
import { 
  collection,
  doc,
  getDocs,
  setDoc,
} from 'firebase/firestore';
import { db } from '../../../firebase';
import { Nutrient } from '../../../interfaces';
import { 
  Container,
  FormContainer,
  FormDesc, 
  FormDescContainer, 
  FormImageContainer, 
  FormImagePreview, 
  FormInput, 
  FormName, 
  FormNameContainer, 
  FormSummary, 
  FormSummaryContainer, 
  Item, 
  ItemName, 
  List, 
  ListContainer, 
  ListTitle, 
  ListTitleContainer, 
  SummitButton, 
  SummitContainer, 
  SummitTitle, 
  Wrapper 
} from './styles';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';

const NutrientView = () => {
  const [ name, setName ] = useState<string>('');
  const [ list, setList ] = useState<Nutrient[]>([]);
  const [summary, setSummary] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [previewURL, setPreviewURL] = useState<string | null>(null);

  const collectionName: string = 'bochung_nutrients';

  const getNutrientList = async () => {
    const docRef = await getDocs(collection(db, collectionName));
    const updatedArr: Nutrient[] = docRef.docs.map((doc) => {return { name: doc.data().name }});
    setList(updatedArr);
  }
  
   const resetInput = () => {
	  setName('');
    setSummary('');
    setDescription('');
    setSelectedFile(null);
    setPreviewURL(null);
  }
  
  const uploadPhotoToFireStorage = async () => {
    const storage = getStorage();
    const fileRef = ref(storage, uuidv4());
    const response = await uploadBytes(fileRef, selectedFile!);
    if(response !== undefined)
    {
      const fileURL = await getDownloadURL(fileRef);
      fetchNutrient(fileURL);
    }
    
  }
  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files![0];
    setSelectedFile(file);

    const reader = new FileReader();

    reader.onload = () => {
      setPreviewURL(reader.result as string);
    }
    reader.readAsDataURL(file as Blob);
  }
  const fetchNutrient = async(fileURL: string) => {


    const docRef =  await setDoc(
      doc(db, collectionName, name), 
      { 
        name: name,
        summary: summary,
        description: description,
        url: fileURL,
      });
  }
  const onClick = () => {
	  uploadPhotoToFireStorage();
	  resetInput();
  }
  useEffect(()=> {
    getNutrientList();
  }, []);
  
  return(
    <Container>
      <Wrapper>
        <ListContainer>

          <ListTitleContainer>
            <ListTitle>등록된 성분들</ListTitle>
          </ListTitleContainer>

          <List>
            {
              list.map((item) => (
                <Item>
                  <ItemName>{item.name}</ItemName>
                </Item>
              ))
            }
          </List>

        </ListContainer>

        <FormContainer>
         
          <FormNameContainer>
            <FormName>이름</FormName>
            <FormInput placeholder="성분 이름" onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)} value={name}/>
          </FormNameContainer>

          <FormSummaryContainer>
            <FormSummary>성분 요약</FormSummary>
            <FormInput placeholder='성분 요약' onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSummary(e.target.value)} value={summary}/>
          </FormSummaryContainer>

          <FormDescContainer>
            <FormDesc>성분 설명</FormDesc>
            <FormInput placeholder='성분 설명' onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDescription(e.target.value)} value={description}/>
          </FormDescContainer>
          <FormImageContainer>
            <FormDesc>성분 이미지</FormDesc>
            <FormInput type="file" accept='image/*' onChange={onFileChange}/>
            {previewURL && <img src={previewURL} alt="PrevireImg"/>}
            <FormImagePreview>

            </FormImagePreview>
          </FormImageContainer>

          <SummitContainer>
            <SummitButton onClick={onClick}>
              <SummitTitle>성분 등록하기</SummitTitle>
            </SummitButton>
          </SummitContainer>

        </FormContainer>

      </Wrapper>
    </Container>
  )
};

export default NutrientView;