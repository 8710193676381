import React,  { useEffect, useState }from 'react';
import { db } from '../../../firebase';
import {
  collection,
  getDocs,
} from 'firebase/firestore';
import {
	ProductViewContainer,
	ProductViewWrapper,
	ChangeModeButtonContainer,
	ChangeModeButtonWrapper,
	ChangeModeButton,
	AdditProductListViewContainer,
	AdditProductListViewWrapper,
	ProductListViewContainer,
	ProductListViewWrapper,
	ProductItemButton,
	NotSelectProductViewContainer,
	NotSelectProductViewWrapper,
	NotSelectProductViewTitle,
	ChangeModeButtonInner,
	ProductItemName,
	
} from './styles';
import {
	AddProductView,
} from './addProduct';
import {
	AdditProductView,
} from './additProduct';

interface ItemProps {
	name: string;
	id: string;
}
const ProducView = () => {
	
  const [modFlag, setMofFlag] = useState<boolean>(true);
	const [item, setItem] = useState<ItemProps>({
		name: '',
		id: '',
	});
  const [list, setList] = useState<ItemProps[]>([]);
	
  const getProducList = async() => {
	  const docRef = await getDocs(collection(db, "bochung_supplements"));
		const list: ItemProps[] = docRef.docs.map((d) => {
			return{
				name: d.data().name,
				id: d.id,
			} 
		});
		
		setList(list);
  }
  
  useEffect(() => {
	  getProducList();
  }, []);
	
  return (
    <ProductViewContainer>
	  <ProductViewWrapper>
		  
	    <ChangeModeButtonContainer>
				<ChangeModeButtonWrapper>
					<ChangeModeButton onClick={() => setMofFlag(true)}>
						<ChangeModeButtonInner>추가하기</ChangeModeButtonInner>
					</ChangeModeButton>	
					<ChangeModeButton onClick={() => setMofFlag(false)}>
						<ChangeModeButtonInner>수정하기</ChangeModeButtonInner>
					</ChangeModeButton>
				</ChangeModeButtonWrapper>
			</ChangeModeButtonContainer>
		  
		{
		  modFlag ?
		    <AddProductView/>
		  :
		    <AdditProductListViewContainer>
					<AdditProductListViewWrapper>
						
						<ProductListViewContainer>
						<ProductListViewWrapper>
							{
							list.map((i) => (
							<ProductItemButton key={i.id} onClick={() => setItem(i)}>
								<ProductItemName isClicked={i.name === item.name ? true : false}>{i.name}</ProductItemName>
							</ProductItemButton>
						))
						}
						</ProductListViewWrapper>
						</ProductListViewContainer>
					{ 
						item.name !== '' ?
							<AdditProductView id={item.id}/>
						: 
							<NotSelectProductViewContainer>
							<NotSelectProductViewWrapper>
								<NotSelectProductViewTitle>제품을 선택해주세요</NotSelectProductViewTitle>
							</NotSelectProductViewWrapper>
						</NotSelectProductViewContainer> 
					}
					</AdditProductListViewWrapper>
				</AdditProductListViewContainer>
		}
	  </ProductViewWrapper>
	</ProductViewContainer>
  );
};

export default ProducView;