import React, { useEffect, useState } from 'react';
import { 
  collection,
  doc,
  getDocs,
  setDoc,
} from 'firebase/firestore';
import { db } from '../../../firebase';
import { Company } from '../../../interfaces';
import { CompanyDetailViewContainer, CompanyListContainer, Container, Wrapper } from './styles';

const CompanyView = () => {
  const [ comapny, setCompany ] = useState<Company>({
	  name: '',
	  url: '',
  });
	
  const {name, url} = comapny;

  const [ list, setList ] = useState<Company[]>([]);

  const collectionName: string = 'bochung_companies';

  const getCompanyList = async () => {
    const docRef = await getDocs(collection(db, collectionName));
    const updatedArr: Company[] = docRef.docs.map((doc) => {return { name: doc.data().name, url: doc.data().url}});
    setList(updatedArr);
  }
  const fetchCompany = async() => {
    const docRef =  await setDoc(doc(db, collectionName, comapny.name), { ...comapny });
  }
  const resetInput = () => {
	  setCompany({
		  name: '',
		  url: '',
	  })
  }
  const onClick = () => {
	  fetchCompany();
	  resetInput();
  }
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
	  const {name, value} = e.target;
	  
	  setCompany({
		  ...comapny,
		  [name]: value,
	  });
	 
  }
  useEffect(()=> {
    getCompanyList();
  }, []);
  
  return(
    <Container>
      <Wrapper>
        
        <CompanyListContainer>
          <div>
            <span>등록된 회사들</span>
          </div>
          {
            list.map((item) => (
              <div key={item.name}>{item.name}</div>
            ))
          }
        </CompanyListContainer>

        <CompanyDetailViewContainer>
          <div>이름</div>
          <div>
            <input placeholder="회사 이름" onChange={onChange} value={name} name='name'/>
          </div>
          <div>회사 홈페이지</div>
          <div>
            <input placeholder="홈페이지 URL" onChange={onChange} value={url} name='url'/>
          </div>
          <div>
            <button onClick={onClick}>
              <span>회사 등록하기</span>
            </button>
          </div>
        </CompanyDetailViewContainer>
      </Wrapper>
    </Container>
  )
};

export default CompanyView;