import React from 'react';
import {
	SupplementName,
	ListItemInfoArea,
	SummeryContainer,
	SummeryNutrientFacts,
	SummeryInner,
} from './styles';

interface NutrientProps {
  name: string;
  summary: string;
	url: string;
}

const NutrientListItem = ({
	nutrient,
}: {
	nutrient: NutrientProps,
}) => (
	<ListItemInfoArea>
		<SummeryContainer>
			<SummeryInner>
				<SupplementName>{nutrient.name}</SupplementName>
				<SummeryNutrientFacts>
					{nutrient.summary}
				</SummeryNutrientFacts>
			</SummeryInner>
		</SummeryContainer>
	</ListItemInfoArea>
);

export default NutrientListItem;