import styled from '@emotion/styled';

interface ButtonProps {
  clicked?: boolean;
}

export const SearchListContainer = styled.div`
	width: 100vw;
	flex:1;
`;
export const SearchListWrapper = styled.div`
`;
export const SearchModButtonContainer = styled.div`
	width: 100%;
`;
export const SearchModButton = styled.button<ButtonProps>`
	padding: 0 10px;
	color: ${(props) => props.clicked ? '#F73C61' : '#B4B4B4'};
	width: 180px;
	height: 30px;
`;
export const SearchModButtonText = styled.span`
	font-size: ${props => props.theme.fontSizes.medium};
`;
export const SearchModButtonUnderline = styled.div<ButtonProps>`
	height: 3px;
	width: 100%;
	background-color: ${(props) => props.clicked ? '#F73C61' : 'transparent'};
	margin-top: 8px;
`;

export const SearchResultListContainer = styled.div`
	width: 100%;

`;
export const SearchResultListWrapper = styled.div`
	padding: 0 18px;
	display: flex;
	flex-direction: row;
	justify-content: center;
`;
export const MyQuestion = styled.div`
	padding: 0 16px;
	margin-top: 169px;
`;

export const ProductListContainer = styled.div`
`;
export const NutrientListContainer = styled.div`
	margin-top: 16px;
`;

export const ListItem = styled.div`
	margin-bottom: 16px;
`;

export const MyQuestionAnnounce = styled.div`
	width: 100%;
	margin-top: 10px;
	color: white;
`;
export const MyQuestionAnnounceInner = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px;
`;
export const NoSearchReaultText = styled.span`
	color: #C4C4C4;
	font-size: 14px;
`;

export const TagListConatiner = styled.div`
	margin-bottom: 16px;
`;
export const TagListWrapper = styled.div`
`;

export const TagButton = styled.button`
	font-size: 14px;
	font-weight: medium;
	border-radius: 4px;
	margin: 8px 8px 0 0;
`;

export const TagActived = styled.div`
	color:${props => props.theme.colors.buttonOnClickText};
	background-color: ${props => props.theme.colors.buttonOnClickBackground};
	border-radius: 4px;
	padding: 4px 8px;
`;
export const TagNonActived = styled.div`
	color: ${props => props.theme.colors.buttonBaseText};
	background-color: ${props => props.theme.colors.buttonBaseBackground};
	border-radius: 4px;
	padding: 4px 8px;
`;

export const SearchPageTopBarContainer = styled.div`
	width: 100%;
	position: fixed;
	top: 0;
	background-color: ${props => props.theme.colors.headerBackground}; 
`;
export const SearchPageTopBarWrapper = styled.div`
	padding: 18px 16px;
	display: flex;
	flex-direction: row;
`;
export const BackButton = styled.button`
	width: 32px;
	height: 32px;
`;
export const BackButtonImg = styled.img``;
export const SearchPageTopBarTitleContainer = styled.div`
	margin: auto;
`;
export const SearchPageTopBarTitle = styled.span`
	color: ${props => props.theme.colors.baseText};
	font-size: 18px;
	font-weight: medium;
`;
export const Dummy = styled.div`
	width: 32px;
	height: 32px;
`;

export const SearchBarConatainer = styled.div`
	padding: 0 16px;
	padding-bottom: 16px;
`;

export const HelpContainer = styled.div`
	margin-top: 8px;
	margin-bottom: 16px;
`;
export const HelppWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 4px;
`;
export const HelpIconImg = styled.img`
	
`;
export const HelpText = styled.span`
	color: ${props => props.theme.colors.baseText};
	font-size: 10px;
`;