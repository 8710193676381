import React,  { useEffect, useState }from 'react';
import { 
  Photo,
  Supplement,
} from '../../../../interfaces';
import { db } from '../../../../firebase';
import {
  ref,
  getStorage, 
  uploadBytes,
  getDownloadURL
} from 'firebase/storage';
import { 
  addDoc,
  collection,
  getDocs,
} from 'firebase/firestore';
import {
	addValueToArray
} from '../../../../utils';
import {
	AddProductViewContainer,
	AddProductViewWrapper,
	ProductContainer,
	ProductTitle,
	ListContainer,
	SelectedItemContainer,
	SelectedListContainer,
	SelectedListWrapper,
	ListItemButton,
	ListItem,
	AddProductButtonContainer,
	AddProductButton,
  PhotoAddContainer,
  PhotoAddWrapper,
  PhotoAddInput,
  PhotoPreview,
  PhotoDeleteButton,
  PhotoPreviewImg,
	
} from './styles';
import { v4 as uuidv4 } from 'uuid';

interface Infos {
  fat: string;
  kcal: string;
  protein: string;
  serving: string;
  carbohydrate: string;
  sugars: string;
}

interface FactsInputList {
	placeholder: string;
	name: string;
	value: string
}

const AddProducView = () => {
  const [ name, setName ] = useState<string>('');
  const [ infos, setInfos ] = useState<Infos>({
	  fat: '',
	  kcal: '',
	  protein: '',
	  serving: '',
	  carbohydrate: '',
	  sugars: '',
  })
  
  const {fat, kcal, protein, serving, carbohydrate, sugars} = infos;

  const [photo, setPhoto] = useState<Photo>({url: 'url'});

  const [ nutrient, setNutrient ] = useState<string[]>([]);
  const [ allNutrientList, setAllNutrientList ] = useState<string[]>([]);

  const [ tag, setTag ] = useState<string[]>([]);
  const [ allTagList, setAllTagList ] = useState<string[]>([]);

  const [ company, setCompany ] = useState<string>('');
  const [ allCompanyList, setAllCompanyList ] = useState<string[]>([]);
	
  const [ desc, setDesc ] = useState<string>('');
  const [ price, setPrice ] = useState<string>('');
  const [ weight, setWeight ] = useState<string>('');
  const [unit, setUnit] = useState<string>('kg');

  const collectionName = 'bochung_supplements';
  const NutrientFactsList: FactsInputList[] = [
	  { placeholder: '지방', name: 'fat' , value: fat},
	  { placeholder: '열량', name: 'kcal', value: kcal },
	  { placeholder: '단백질', name: 'protein', value: protein},
	  { placeholder: '1회 제공량', name: 'serving', value: serving },
	  { placeholder: '탄수화물', name: 'carbohydrate', value: carbohydrate },
	  { placeholder: '당류', name: 'sugars', value: sugars },  
  ]
  const [productLink, setProductLink] = useState<string>('');

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [previewURL, setPreviewURL] = useState<string | null>(null);

  const fetchProduct = async(data: Supplement) => {
    await addDoc(collection(db, collectionName), data);
  }

  const handleCheckTag = (t: string) => {
    const updatedTagList: string[] = addValueToArray<string>(tag, t);
    setTag(updatedTagList);
  }
  const handleCheckNutrient = (n: string) => {
    const updatedNutrientList: string[] = addValueToArray<string>(nutrient, n);
    setNutrient(updatedNutrientList);
  }

  const getTagList = async() => {
    const docRef = await getDocs(collection(db, 'bochung_tags'));
    const docData: string[] = docRef.docs.map((doc) => {
      return doc.data().name;
    });

    setAllTagList(docData);
  }

  const getNutrientList = async () => {
    const docRef = await getDocs(collection(db, 'bochung_nutrients'));
    const docData: string[] = docRef.docs.map((doc) => {
      return doc.data().name;
    });

    setAllNutrientList(docData);
  }

  const getCompanyList = async () => {
    const docRef = await getDocs(collection(db, 'bochung_companies'));
    const docData: string[] = docRef.docs.map((doc) => {
      return doc.data().name;
    });

    setAllCompanyList(docData);
  }
  const uploadPhotoToFireStorage = async () => {
    const storage = getStorage();
    const fileRef = ref(storage, uuidv4());
    const response = await uploadBytes(fileRef, selectedFile!);
    if(response !== undefined)
    {
      const fileURL = await getDownloadURL(fileRef);
      addSupplement(fileURL);
    }
    
  }
  const onChangeFacts = (e: React.ChangeEvent<HTMLInputElement>) => {
	  const {name, value} = e.target;
	  
	  setInfos({
		  ...infos,
		  [name]: value,
	  });
	 
  } 
  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files![0];
    setSelectedFile(file);

    const reader = new FileReader();

    reader.onload = () => {
      setPreviewURL(reader.result as string);
    }
    reader.readAsDataURL(file as Blob);
  }
  const handlePhotoDelete = () => {
    setSelectedFile(null);
    setPreviewURL(null);
  }

  const addSupplement = (fileURL: string) => {
    const data: Supplement = {
      name: name,
      company: company,
      infos: {
      fat:parseFloat(infos.fat),
      kcal: parseFloat(infos.kcal),
      protein: parseFloat(infos.protein),
      serving: parseFloat(infos.serving),
      carbohydrate: parseFloat(infos.carbohydrate),
      sugars: parseFloat(infos.sugars),
    },
      tags: tag,
      photo: {
        url: fileURL
      },
      nutrients: nutrient,
      price: parseInt(price),
      weight: parseFloat(weight),
      unit: unit,
    }
  
    fetchProduct(data);
    
   
  }

  const onClick = () => {
    uploadPhotoToFireStorage();
    
    setName('');
    setTag([]);
    setNutrient([]);
    setDesc('');
    setInfos({
      fat: '',
      kcal: '',
      protein: '',
      serving: '',
      carbohydrate: '',
      sugars: '',
    });
    setPhoto({
      url: '',
    });
    setCompany('');
    setPrice('');
    setWeight('');
    setPreviewURL('');
    
  }


  useEffect(() => {
    getNutrientList();
    getTagList();
    getCompanyList();

    
  }, [ tag, infos]);

  return (
    <AddProductViewContainer>
	  <AddProductViewWrapper>
      <ProductContainer>
        <ProductTitle>제품 링크</ProductTitle>
        <input 
          placeholder="제품 링크"
          onChange={(e) => setProductLink(e.target.value)}
          value={productLink}
        />
      </ProductContainer>
      <ProductContainer>
        <ProductTitle>이름</ProductTitle>
        <input 
          placeholder="이름"
          onChange={(e) => setName(e.target.value)}
          value={name}
        />
      </ProductContainer>
		  
      <ProductContainer>
        <ProductTitle>가격</ProductTitle>
        <input 
          placeholder="가격"
          onChange={(e) => setPrice(e.target.value)}
          value={price}
        />
      </ProductContainer>
      <ProductContainer>
        <ProductTitle>용량</ProductTitle>
        <input 
        placeholder="용량"
        onChange={(e) => setWeight(e.target.value)}
        value={weight}
        />
        {unit}
        <button onClick={() => setUnit('kg')}>kg</button>
        <button onClick={() => setUnit('ml')}>ml</button>
      </ProductContainer>
		  
		  
      <ProductContainer>
        <ProductTitle>회사</ProductTitle>
        <ListContainer>
          {
            allCompanyList.map((c) => (
              <ListItemButton key={c} onClick={() => setCompany(c)}>
                <span>{c} ||</span>
              </ListItemButton>
            ))
          }
        </ListContainer>
        <SelectedItemContainer>
          <ListItem>
            {company}
          </ListItem>
        </SelectedItemContainer>
      </ProductContainer>
		  
		  
      <ProductContainer>
        <ProductTitle>영양정보를 기입해주세요</ProductTitle>
        <div>
          {
            NutrientFactsList.map((i) => 
              <input 
                key={i.name}
                placeholder={i.placeholder} 
                onChange={onChangeFacts} 
                name={i.name} 
                value={i.value}
              />
            )
          }
        </div>
      </ProductContainer>

      <ProductContainer>
        <ProductTitle>성분</ProductTitle>
        <ListContainer>
          {
            allNutrientList.map((nutrient) => (
              <ListItemButton key={nutrient} onClick={() => handleCheckNutrient(nutrient)}>
                <span>{nutrient}||</span>
              </ListItemButton>
            ))
          }
        </ListContainer>
        <SelectedListContainer>
          <SelectedListWrapper>
              {
                nutrient.map((n) => (
                  <ListItem key={n}>{n} ||</ListItem>
                ))
              }
          </SelectedListWrapper>
        </SelectedListContainer>
        
      </ProductContainer>
      <ProductContainer>
        <ProductTitle>사진</ProductTitle>
        <PhotoAddContainer>
          <PhotoAddWrapper>
            <PhotoAddInput type='file' accept='image/*' onChange={onFileChange}/>
            <PhotoPreview>
              {previewURL && <PhotoPreviewImg src={previewURL} alt="PrevireImg"/>}
              <PhotoDeleteButton onClick={handlePhotoDelete}>삭제</PhotoDeleteButton>
            </PhotoPreview>
          </PhotoAddWrapper>
        </PhotoAddContainer>
      </ProductContainer>
      <ProductContainer>
		<ProductTitle>태그</ProductTitle>
        <ListContainer>
          {
            allTagList.map((tag) => (
              <ListItemButton key={tag} onClick={() => handleCheckTag(tag)}>
                <span>{tag}||</span>
              </ListItemButton>
            ))
          }
        </ListContainer>
        <SelectedListContainer>
            <SelectedListWrapper>
              {
                tag.map((t) => (
                  <ListItem key={t}>{t} ||</ListItem>
                ))
              }
            </SelectedListWrapper>
        </SelectedListContainer>
      </ProductContainer>
		  
		  <AddProductButtonContainer>
			<AddProductButton onClick={() => onClick()}>
			  <span>상품 등록하기</span>
			</AddProductButton>
		  </AddProductButtonContainer>
		  
		</AddProductViewWrapper>
    </AddProductViewContainer>
  );
};

export default AddProducView;