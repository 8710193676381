import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import {
	SearchListContainer,
	SearchListWrapper,
	SearchModButtonContainer,
	SearchModButton,
	MyQuestion,
	SearchResultListContainer,
	SearchResultListWrapper,
	NutrientListContainer,
	ProductListContainer,
	ListItem,
	MyQuestionAnnounce,
	MyQuestionAnnounceInner,
	TagListWrapper,
	TagListConatiner,
	TagActived,
	TagButton,
	TagNonActived,
	SearchPageTopBarContainer,
	SearchPageTopBarWrapper,
	BackButton,
	BackButtonImg,
	SearchPageTopBarTitle,
	SearchPageTopBarTitleContainer,
	Dummy,
	SearchBarConatainer,
	HelpContainer,
	HelppWrapper,
	HelpIconImg,
	HelpText,
	SearchModButtonUnderline,
	NoSearchReaultText,
	SearchModButtonText,
} from './styles';
import {
	collection, 
	getDocs,
	query, 
	where,
} from 'firebase/firestore';
import { db } from '../../firebase';
import { SupplementListItem } from '../../components/item';
import { addValueToArray } from '../../utils';
import NutrientListItem from '../../components/item/nutrient/NutrientListItem';
import BackArrowIcon from '../../utils/imgs/arrow_back_ios.svg';
import HelpIcon from '../../utils/imgs/help_icon.svg';
import { Footer, SearchBar } from '../../components';
import ListPopup from '../../components/modals/listPopup';
import MetaTag from '../../components/meta';

interface NutrientProps {
	name: string;
}
interface SupplementInfoProps{
	kcal: number;
	protein: number;
	fat: number;
	serving: number;
}
interface CompanyProps {
	id: string;
	name: string;
}
interface SupplementProps {
	id: string;
	company: CompanyProps;
	name: string;
	photo: {
		url: string;
	};
	nutrients: any[];
	tags: string[];
	infos: SupplementInfoProps;
	desc: string;
	price: number;
	weight: number;
}
interface SortType {
	index: string;
	title: string;
}
const SearchPage = () => {
	const [ searchParams ] = useSearchParams();
  const question = searchParams.get('question') || ' ';
	
	const [ nutrientList, setNutrientList ] = useState<any[]>([]);
	const [ productList, setProductList ] = useState<any[]>([]);
	const [ searchMod, setSearchMod] = useState<boolean>(false);
	const [ tags, setTags] = useState<string[]>([]);
	const [ selectedTags, setSelectedTags] = useState<string[]>([]);
	const [ listPopupstate, setListPopupState] = useState<boolean>(false);
	const [ currentNutrient, setCurrentNutrient] = useState<NutrientProps>();
	
	const navigate = useNavigate();

	const  toUpperCase = (str: string) =>  {
  	return str.toUpperCase();
	}
	
	const getProductListFromFirebaseWithName = async () => {
		
		const q = query(
			collection(db, 'bochung_supplements'),
			where('name', '>=', toUpperCase(question)),
			where('name', '<=', toUpperCase(question) + '\uf8ff')
		);
		const querySnapshot = await getDocs(q);
		const docsData = querySnapshot.docs.map((doc) => {return {data: doc.data(), id: doc.id}} );
		
		if(selectedTags.length !== 0)
		{
			const updatedData: any[]  = docsData.filter((data) => {
				for(const t in selectedTags) {
					if(data.data.tags.includes(selectedTags[t])) return data;
				}
			});
			setProductList(updatedData);
		} else setProductList(docsData);
		
	}
	const getNutrientListFromFirebaseWithNutrient = async () => {
		
		const q = query(collection(db, 'bochung_nutrients'), where("name", "==", toUpperCase(question)) );
		const querySnapshot = await getDocs(q);
		const docsData = querySnapshot.docs.map((doc) => {return {data: doc.data(), id: doc.id}});
		setNutrientList(docsData);
		
	}
	const getTagList = async () => {
		const querySnapshot = await getDocs(collection(db, 'bochung_tags'));
		const docsData = querySnapshot.docs.map((doc) => {return doc.data().name});

		setTags(docsData);
	}
	const handleSelectTag = (t: string) => {
		const updatedTagList: string[] = addValueToArray<string>(selectedTags, t);
    setSelectedTags(updatedTagList);
	}
	const onClickBackButton = () => {
		navigate('/');
	}
	const getSortedList = (sortType: SortType) => {

		const list: SupplementProps[]  = productList;
		if(sortType.index === 'desc') {
			list.sort((a: SupplementProps, b: SupplementProps): number => {
				return a.price - b.price;
			}); 
		} else if(sortType.index === 'incr') {
			list.sort((a: SupplementProps, b: SupplementProps): number => {
				return b.price - a.price;
			});
		} else if(sortType.index === 'recommend') {
			list.sort((a: SupplementProps, b: SupplementProps): number => {
				return b.price - a.price;
			});
		}
		console.log(list);
		return list;
	}
	const sortList: SortType[] = [
		{
			title: '낮은 가격 순',
			index: 'desc'
		},
		{
			title: '높은 가격 순',
			index: 'incr'
		}
	];
	const [sortType, setSortType] = useState<SortType>(sortList[0]);

	const handleListPopupState = (state: boolean, item: NutrientProps) => {
		setListPopupState(state); 
		setCurrentNutrient(item);
	}
	useEffect(() => {
		if(!searchMod) {
			getProductListFromFirebaseWithName();
			getTagList();
		} else {
			getNutrientListFromFirebaseWithNutrient();
		}
		if(listPopupstate) document.body.style.overflow = 'hidden';
		else document.body.style.overflow = 'unset';
	}, [
		question, 
		searchMod, 
		selectedTags,
		listPopupstate,
	]);
	
	return (
		<>
		<MetaTag
			title={`보충 - ${question}`}
			description={`상품 검색: ${question}`}
			url={`https://bo-chung.com/search?question=${question}`}
		/>
		<SearchListContainer>
			<SearchListWrapper>
				
				<SearchPageTopBarContainer>
					<SearchPageTopBarWrapper>
						<BackButton onClick={onClickBackButton}>
							<BackButtonImg src={BackArrowIcon}/>
						</BackButton>
						<SearchPageTopBarTitleContainer>
							<SearchPageTopBarTitle>검색</SearchPageTopBarTitle>
						</SearchPageTopBarTitleContainer>
						<Dummy/>
					</SearchPageTopBarWrapper>
					<SearchBarConatainer>
						<SearchBar/>
					</SearchBarConatainer>
					<SearchModButtonContainer>
					<SearchResultListWrapper>
						<SearchModButton onClick={() => setSearchMod(false)} clicked={!searchMod}>
							<SearchModButtonText>보충제 찾기</SearchModButtonText>
							<SearchModButtonUnderline clicked={!searchMod}/>
						</SearchModButton>
						<SearchModButton onClick={() => setSearchMod(true)} clicked={searchMod}>
							<SearchModButtonText>성분 찾기</SearchModButtonText>
							<SearchModButtonUnderline clicked={searchMod}/>
						</SearchModButton>
					</SearchResultListWrapper>
				</SearchModButtonContainer>
			</SearchPageTopBarContainer>
				
			{
				listPopupstate ?
					<ListPopup
						title={currentNutrient!.name}
						state={listPopupstate}
						handleSort={setSortType}
						onCloseButton={setListPopupState}
						sortList={sortList}
					/>
					:
					<></>
			}
				<MyQuestion>
					<SearchResultListContainer>
						{
							!searchMod ?
							<>
								<TagListConatiner>
										<TagListWrapper>
											{tags.map((tag) => (
												<TagButton key={tag} onClick={() => handleSelectTag(tag)}>
													{
														selectedTags.includes(tag) ?
															<TagActived>{tag}</TagActived>
														:
															<TagNonActived>{tag}</TagNonActived>
													}
												</TagButton>
											))}
										</TagListWrapper>
								</TagListConatiner>
								{productList.length === 0 ?
									<MyQuestionAnnounce>
										<MyQuestionAnnounceInner>
											<NoSearchReaultText>검색 결과가 없어요</NoSearchReaultText>
										</MyQuestionAnnounceInner>
									</MyQuestionAnnounce>
								:
									<ProductListContainer>

										{productList.map((item) => (
											<ListItem 
												key={item.id}
												onClick={() => navigate(`/detail?id=${item.id}`)}
											>
												<SupplementListItem supplement={item.data}/>
											</ListItem>
										))}
									</ProductListContainer>
								
								}
							</>
							:
								nutrientList.length === 0 ?
									<MyQuestionAnnounce>
										<MyQuestionAnnounceInner>
											<NoSearchReaultText>검색 결과가 없어요</NoSearchReaultText>
										</MyQuestionAnnounceInner>
									</MyQuestionAnnounce>
								:
									<NutrientListContainer>
										<HelpContainer>
											<HelppWrapper>
												<HelpIconImg src={HelpIcon} alt="help_icon"/>
												<HelpText>성분을 선택하면 관련 상품을 확인 할 수 있어요</HelpText>
											</HelppWrapper>
										</HelpContainer>
										{nutrientList.map((item) => (
											<ListItem 
												key={item.id}
												onClick={() => handleListPopupState(true, item.data)}
											>
												<NutrientListItem nutrient={item.data}/>
											</ListItem>
										))}
									</NutrientListContainer>
						}
					</SearchResultListContainer>
				</MyQuestion>
			</SearchListWrapper>
		</SearchListContainer>
		<Footer/>
		</>
	);
}

export default SearchPage;