import React, { useEffect, useState } from 'react';
import { 
  CloseButton,
  CloseButtonImage,
  Container,
  Dummy,
  Item,
  ListConatiner,
  ScreenBlock,
  Title, 
  TitleBar, 
  Wrapper 
} from './styles';
import {
	collection, 
	getDocs,
	query, 
	where,
} from 'firebase/firestore';
import { db } from '../../../firebase';
import CloseButtonImg from '../../../utils/imgs/Vector.svg';
import { SupplementListItem } from '../../item';
import { useNavigate } from 'react-router-dom';

interface SupplementInfoProps{
	kcal: number;
	protein: number;
	fat: number;
	serving: number;
}
interface CompanyProps {
	id: string;
	name: string;
}
interface SupplementProps {
	id: string;
	company: CompanyProps;
	name: string;
	photo: {
		url: string;
	};
	nutrients: any[];
	tags: string[];
	infos: SupplementInfoProps;
	desc: string;
	price: number;
	weight: number;
}
interface SortType {
	index: string;
	title: string;
}
const ListModal = ({
    title,
    handleSort,
    onCloseButton,
    state,
    sortList
}: {
    title: string,
    handleSort: (s: SortType, ) => void,
    onCloseButton: (s: boolean) => void,
    state: boolean,
    sortList: SortType[]
}) => {
  const [list, setList ] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
	const navigate = useNavigate();
  const getProductListFromFirebaseWithNutrient = async (nutrientName: string) => {
		
		const q = query(collection(db, 'bochung_supplements'), where("nutrients", "array-contains", nutrientName) );
		const querySnapshot = await getDocs(q);
		const docsData = querySnapshot.docs.map((doc) => {return {data: doc.data(), id: doc.id}});
		setList(docsData);
		setLoading(true);
	}

  useEffect(() => {
    getProductListFromFirebaseWithNutrient(title);
  }, []);

  return (
    <ScreenBlock isActive={state}>
      <Container>
        <Wrapper isActive={state}>
          <TitleBar>
            <Dummy/>
            <Title>{title}</Title>
            <CloseButton onClick={() => onCloseButton(false)}>
              <CloseButtonImage src={CloseButtonImg} alt='close_button_img'/>
            </CloseButton>
          </TitleBar>
          <ListConatiner>
            {
              list.map((i)=>(
                <Item onClick={() => navigate(`/detail?id=${i.id}`)} key={i.id}>
                  <SupplementListItem
                    supplement={i.data}
                  />
                </Item>
              ))
            }
          </ListConatiner>
        </Wrapper>
      </Container>
    </ScreenBlock>
  )
};

export default ListModal;